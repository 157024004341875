import React from 'react'
import {Redirect} from 'react-router-dom'

// API
import {authenticationService} from '../api'

function Home() {

    const orgMemberInfo = authenticationService.organizationsMemberValue

    // If the user has only one organization, use that one
    if(Object.keys(orgMemberInfo).length === 1){
        let orgId = Object.keys(orgMemberInfo)[0]
        return <Redirect to={`/organization/${orgId}`}/>
    }   
    else{ // If we don't know what organization to use
        return <Redirect to={`/organizations/`}/>
    }
}

export default Home