import React from 'react'

// Components
import Title from '../../components/Elements/Title'
import VerificationForm from '../../components/RegistrationForms/VerificationForm'

function RegistCard(props) {
    return (
        <div className="regist-card col-4 screen-center" >
            <div className="center">
                <Title text="Email verification" />
            </div>
            <VerificationForm signup_info={props.signup_info} onChange={props.onChange}/>
        </div>
    )
}

export default RegistCard