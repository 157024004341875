import { useState, useContext } from "react";
import { UserContext } from "../../components/Hooks/UserContext";
import SecuritySetup from "../../components/Security/SecuritySetup";
import styled from "styled-components";

const SecurityContent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [method, setMethod] = useState("");
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const handleOpen = (method_selected) => {
    setMethod(method_selected);
    setIsOpen(true);
  };

  const handleClose = () => {
    setMethod("");
    setIsOpen(false);
  };

  return (
    <>
      <section className="simple-profile">
        <div className="simple-profile__sections">
          <p className="title-2 is-bold is-dark line-margin-0">
            Security Settings
          </p>
        </div>
        <div className="simple-profile__sections">
          <p className="subtitle is-normal line-margin-0">
            <i className="fa fa-info-circle is-orange mr-5" /> Remember that you can have only one authentication method selected at a time
          </p>
        </div>
        <div className="simple-profile__sections mt-30">
          <p className="title-3 is-bold is-dark line-margin-0">
            App Authentication Method
          </p>
          <div className="flexer flexer-spaced flexer-vcenter my-20">
            <p className="text is-dark line-margin-0 half-width">
              On your phone install{" "}
              <span className="text is-bold">
                Google or Other Authenticator App
              </span>
              . You will have to generate an entry code in the app every time
              you log in
            </p>
            <div className="">
              <StyledButton
                className="white-btn mx-15 cursor flexer flexer-vcenter"
                onClick={() => handleOpen("APP")}
                style={{ padding: "0 10px", borderRadius: "5px" }}
                disabled={currentUser.mfa_settings.SoftwareTokenMfaSettings.PreferredMfa}
              >
                {currentUser.mfa_settings.SoftwareTokenMfaSettings
                  .PreferredMfa && (
                  <i className="fa fa-check-circle-o is-green mr-10" />
                )}
                <p className="text is-light">
                  {currentUser.mfa_settings.SoftwareTokenMfaSettings
                    .PreferredMfa
                    ? "Selected Method"
                    : "Set App as Selected 2FA Method"}
                </p>
              </StyledButton>
            </div>
          </div>
        </div>
        <div className="simple-profile__sections mt-30">
          <p className="title-3 is-bold is-dark line-margin-0">
            SMS Authentication Method
          </p>
          <div className="flexer flexer-spaced flexer-vcenter my-20">
            <p className="text is-dark line-margin-0 half-width">
              You will receive an SMS message to your phone every time you log
              in as a second security factor
            </p>
            <div className="">
              <StyledButton
                className="white-btn mx-15 cursor flexer flexer-vcenter"
                onClick={() => handleOpen("SMS")}
                style={{ padding: "0 10px", borderRadius: "5px" }}
                disabled={currentUser.mfa_settings.SMSMfaSettings.PreferredMfa}
              >
                {currentUser.mfa_settings.SMSMfaSettings.PreferredMfa && (
                  <i className="fa fa-check-circle-o is-green mr-10" />
                )}
                <p className="text is-light">
                  {currentUser.mfa_settings.SMSMfaSettings.PreferredMfa
                    ? "Selected Method"
                    : "Set SMS as Selected 2FA Method"}
                </p>
              </StyledButton>
            </div>
          </div>
        </div>
        <SecuritySetup
          isOpen={isOpen}
          handleClose={handleClose}
          currentUser={currentUser}
          currentMethod={method}
          updateSecurity={setCurrentUser}
        />
      </section>
    </>
  );
};

const StyledButton = styled.button`
  :disabled{
    background-color: transparent
  }
`

export default SecurityContent;
