import { useReducer } from 'react'

import CartContext from './Context'
import cartReducer from './Reducer'

const INITIAL_STATE = {
	isLoading: true,
	cartProducts: [],
}

const CartProvider = ({ children }) => {
	const [state, dispatch] = useReducer(cartReducer, INITIAL_STATE)

	const setCartProducts = (product) => {
		dispatch({ type: 'setCart', payload: product })
	}

	return (
		<CartContext.Provider value={{ ...state, setCartProducts }}>{children}</CartContext.Provider>
	)
}

export default CartProvider
