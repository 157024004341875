import config from './config'
import handleResponse from './handleResponse'
import { fixBody } from './helpers'

export const subscriptionService = {
	create,
	list,
	update,
	apply_code
}

async function create(body) {
	// await checkToken()
	body = fixBody(body)
	const requestOptions = { method: 'POST', credentials: 'include', headers:{'Content-Type': 'application/json'}, body: JSON.stringify(body) }
	return fetch(`${config.apiUrl}/subscription/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function list(org_id) {
	const requestOptions = { method: 'GET', credentials: 'include' }

	return fetch(`${config.apiUrl}/organization/${org_id}/subscriptions/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}


async function update(subscription_id, body) {
	
	body = fixBody(body)
	const requestOptions = { method: 'PATCH', credentials: 'include', headers:{'Content-Type': 'application/json'}, body: JSON.stringify(body) }
	return fetch(`${config.apiUrl}/subscription/${subscription_id}/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function apply_code(body) {
	
	body = fixBody(body)
	const requestOptions = { method: 'POST', credentials: 'include', headers:{'Content-Type': 'application/json'}, body: JSON.stringify(body) }
	return fetch(`${config.apiUrl}/subscription/apply_coupon/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}