const cartReducer = ( state, action ) => {
	switch ( action.type ) {
		case 'setCart':
			return {
				...state,
				isLoading   : false,
				cartProducts: action.payload,
			}

		default:
			return state
	}
}

export default cartReducer
