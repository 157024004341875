import config from './config'
import handleResponse from './handleResponse'
import { fixBody } from './helpers'

export const userService = {
	details,
	get_lms_url,
	search,
	list,
	update,
	request_verification,
	deny_verification,
	accept_terms,
	delete_user,
	request_org,
	create_feedback,
	verify_attribute,
}

async function details() {
	const requestOptions = { method: 'GET', credentials: 'include' }
	return fetch(`${config.apiUrl}/users/details/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}
async function get_lms_url () {
	const requestOptions = {
		method     : 'GET',
		credentials: 'include'
	}

	return fetch(
		`${ config.apiUrl }/users/lsm_url/`,
		requestOptions
	)
		.then(handleResponse)
		.then((url) => {
			return url
		})
}
async function search(keyword, org_id) {
	//await checkToken()

	const requestOptions = { method: 'GET', credentials: 'include' }
	return fetch(
		`${config.apiUrl}/organization/${org_id}/users/search/?keyword=${keyword}`,
		requestOptions
	)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function list(org_id) {
	// migrada a memberService, confirmar si la elimino

	//await checkToken()

	const requestOptions = { method: 'GET' }
	return fetch(`${config.apiUrl}/organization/${org_id}/members/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function update(body, user_id) {
	const bodyFixed = fixBody(body)
	const requestOptions = {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(bodyFixed),
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/users/${user_id}/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function request_verification(user_id) {
	//await checkToken()

	const requestOptions = { method: 'POST' }
	return fetch(`${config.apiUrl}/users/${user_id}/request_verification/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function deny_verification(user_id, body) {
	//await checkToken()

	body = fixBody(body)

	const requestOptions = { method: 'POST', body }
	return fetch(`${config.apiUrl}/users/${user_id}/deny_verification/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

function accept_terms(user_id) {
	const requestOptions = { method: 'POST' }
	return fetch(`${config.apiUrl}/users/${user_id}/accept_terms/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function delete_user(user_id) {
	//await checkToken()

	const requestOptions = { method: 'DELETE' }
	return fetch(`${config.apiUrl}/users/${user_id}/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function request_org(body) {
	//await checkToken()
	body = fixBody(body)
	const requestOptions = { method: 'POST', body }
	return fetch(`${config.apiUrl}/users/invitations/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function create_feedback(body) {
	let object = {}
	body.forEach((value, key) => (object[key] = value))

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(object),
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/jira/create_issue/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function verify_attribute(body) {
	const bodyFixed = fixBody(body)
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(bodyFixed),
	}

	return fetch(`${config.apiUrl}/users/verify_user_attribute/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}