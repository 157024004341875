import NumberFormat from 'react-number-format'
import styled from 'styled-components'

import benefitIcon from '../../../assets/img/benefit_item.png'
import { BtnSolid } from '../../atoms/index.atoms'

const PERIODICITY_MAPPING = {
	daily: '/ day',
	monthly: '/ month',
	annual: '/ year'
}
const CardProduct = ({
	cta,
	logo,
	name,
	price,
	benefits,
	isDisabled,
	description,
	isEnterprise = false,
	btnName = 'Add To Cart',
}) => (
	<CardProductStyled>
		<TitleStyled>{name}</TitleStyled>
		<figure
			style={{
				margin: 0,
				padding: 0,
				width: '100%',
				textAlign: 'center',
				paddingBottom: '1rem',
				borderBottom: '1px solid #ececec',
			}}
		>
			<img style={{ height: '4rem' }} src={logo} alt={`Logo ${name}`} />
		</figure>
		{!isEnterprise && benefits && (
			<BenefitsStyled>
				{benefits?.map((benefit, i) => (
					<li
						style={{
							listStyleType: 'none',
							display: 'inline-grid',
							gridAutoFlow: 'column',
							alignItems: 'center',
						}}
						key={`item-${benefit.trim}-${i}`}
					>
						<img src={benefitIcon} alt={benefit} style={{ marginRight: '.75rem' }} />
						{benefit}
					</li>
				))}
			</BenefitsStyled>
		)}
		<DescriptionStyled isEnterprise={isEnterprise}>{description}</DescriptionStyled>
		{price?.price && price?.price >= 1 && (
			<span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<small style={{ lineHeight: '1.2' }}>Your price </small>
				<strong style={{ textAlign: 'center' }}>
					<NumberFormat
						prefix={'$'}
						value={price.price}
						decimalScale={2}
						displayType={'text'}
						thousandSeparator={true}
						fixedDecimalScale={true}
						style={{
							fontSize: '1rem',
							lineHeight: '1.2',
							fontWeight: 'bold',
							marginRight: '.15rem',
						}}
						thousandsGroupStyle={'thousand'}
					/>
					{PERIODICITY_MAPPING[price.periodicity]}
				</strong>				
				{price.periodicity === 'monthly' ? <br></br> : ''}
				{price.periodicity === 'annual' ? 
				<text style={{ textAlign: 'center' }} >
					{'('}
					<NumberFormat
						prefix={'$'}
						value={price.price/12}
						decimalScale={2}
						displayType={'text'}
						thousandSeparator={true}
						fixedDecimalScale={true}
						style={{
							fontSize: '0.8rem',
							lineHeight: '1',
							marginRight: '.15rem',
						}}
						thousandsGroupStyle={'thousand'}
					/>
					{'/ month)'}
				</text> : ''}
			</span>
		)}
		{(price?.price === 0 || !price?.price) && !isEnterprise && (
			<span
				style={{
					fontSize: '1rem',
					fontWeight: 'bold',
					margin: '0 .25rem',
					lineHeight: '1.75rem',
				}}
			>
				Free
			</span>
		)}
		<BtnSolid
			cta={cta}
			name={btnName}
			type={'button'}
			isDisabled={isDisabled}
			style={{ width: '100%' }}
		/>
	</CardProductStyled>
)

const CardProductStyled = styled.article`
	width: 100%;
	height: 100%;
	row-gap: 1rem;
	display: flex;
	padding: 1.5rem;
	min-height: 30rem;
	max-height: 50rem;
	align-items: center;
	border-radius: 0.5rem;
	flex-direction: column;
	background-color: var(--card-bg);
	box-shadow: 0 0 10px 4px #eee;
`

const DescriptionStyled = styled.p`
	margin: 0;
	width: 100%;
	flex-grow: 2;
	text-align: center;
	height: calc(1.2rem * 5);
	line-height: 1.2rem;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	-moz-line-clamp: 5;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	/* flex-grow: ${(props) => props.isEnterprise && '2'}; */
`

const BenefitsStyled = styled.ul`
	margin: 0;
	padding: 0;
	width: 100%;
	flex-grow: 2;
	row-gap: 1rem;
	display: inline-grid;
	align-content: baseline;
	justify-content: center;
`

const TitleStyled = styled.span`
	color: #f69221;
	min-width: 6rem;
	line-height: 1.2;
	font-weight: bold;
	border-width: 1px;
	text-align: center;
	width: fit-content;
	border-style: solid;
	border-radius: 1.5rem;
	border-color: #f69221;
	padding: 0.625rem 1rem;
	text-transform: capitalize;
`

export default CardProduct
