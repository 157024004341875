import SecurityContent from "../containers/Content/SecurityContent"
import { useHistory } from 'react-router-dom'
import { HeaderWrapper } from '../components/organisms/index.organisms'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Security = () => {
	const history = useHistory()

	return (
		<>
		<HeaderWrapper />
		<BackButtonStyled onClick={() => history.push('/')} type={'button'}>
			<FontAwesomeIcon icon={faAngleLeft} />
			Back to Home
		</BackButtonStyled>
		<WrapperStyled>
			<SecurityContent />
		</WrapperStyled>
	</>
	)
}

const WrapperStyled = styled.section `
	background-color: var(--card-bg);
	border-radius: .25rem;
  margin: 0 8rem;
  border: 1px solid var(--border)
  
`

const BackButtonStyled = styled.button`
	cursor: pointer;
	margin-top: 1rem;
	margin-left: 8rem;
	margin-bottom: 1rem;
	padding: 0.5rem 1rem;
	color: var(--orange-btn);
	border: 1px solid var(--border);
	border-radius: 0 1.5rem 1.5rem 0;
	background-color: var(--card-bg);

	& svg {
		margin-right: 0.25rem;
		& path {
			fill: var(--orange-btn) !important;
		}
	}
`

export default Security