import React from 'react'
import {CardCvcElement, CardExpiryElement, CardNumberElement} from '@stripe/react-stripe-js'
import styled from 'styled-components'

import CardIcons from '../CardIcons/CardIcons'

const CheckoutForm = ( { setAcceptTerms } ) => {
	return <Content>
		<Title>Pay with Credit Card</Title>
		<CardIcons/>

		<Fieldset>
			<Label>Card number</Label>
			<CardNumberElement options={ { ...CARD_OPTIONS, showIcon: true } }/>
		</Fieldset>

		<Fieldset>
			<Label>Expiration Date</Label>
			<CardExpiryElement options={CARD_OPTIONS}/>
		</Fieldset>

		<Fieldset>
			<Label>Card Security Code</Label>
			<CardCvcElement options={CARD_OPTIONS}/>
		</Fieldset>

		<Fieldset/>
		<Fieldset style={{gridColumnStart: 1, gridColumnEnd: 3}}>
			<input type="checkbox" id={'terms_conditions'}
						 name={'terms & conditions'}
						 onClick={({target}) => setAcceptTerms(target.checked)}/>
      <label htmlFor={'terms_conditions'}> You authorize Echo Analytics Group to charge your account for the amount listed. Your subscription renews automatically each year until you cancel at the yearly rate, minus any applicable discounts. By checking the box, you understand and agree to these terms and Echo Analytics Group’s <a className='is-blue' target="_blank" href="https://692348871699-echo-files.s3.us-east-2.amazonaws.com/media/torch/docs/APPENDIX_A-TERMS_AND_CONDITIONS.docx">Terms and Conditions</a> and <a className='is-blue' target="_blank" href="https://echoanalyticsgroup.com/privacy/">Privacy Policy</a>.</label>
		</Fieldset>
	</Content>
}

const Content = styled.div`
  gap: 1rem;
  display: grid;
  padding: 20px;
  border-radius: 4px;
  background-color: #dee8f8;
  grid-template-columns: repeat(2, 1fr);
  border: solid 1px var(--orange-btn-hover);
`

const Fieldset = styled.div`
  overflow: hidden;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`

const Label = styled.div`
  background: #dee8f8;
  color: #828282;
  font-size: 12px;
  position: relative;
  z-index: 1;
  margin-left: 6px;
  display: inline-block;
  padding: 0 7px;
  font-weight: 300;
`

const CARD_OPTIONS = {
	iconStyle: 'solid', style: {
		base: {
			fontFamily: 'Lato,Helvetica,sans-serif',
			fontSize: '15px',
			fontWeight: '300',
			'::placeholder': {
				color: '#828282',
			},
		}, invalid: {
			iconColor: 'red', color: 'red',
		},
	},
}

export default CheckoutForm
