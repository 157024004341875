import PropTypes from 'prop-types'
import styled from 'styled-components'
import {FormLabel} from '../../atoms/index.atoms'

const FormFieldSet = ( { children, name, className, isRequired, style, onErr, errMsg } ) => {
	return (
		<StyledField className={ className } style={ style }>
			<FormLabel name={ name } isRequired={ isRequired } onErr={ onErr } errMsg={ errMsg ?? 'This field is required' }/>
			{ children }
		</StyledField>
	)
}

FormFieldSet.propTypes = {
	children: PropTypes.element.isRequired,
	name: PropTypes.string,
	className: PropTypes.string,
	isRequired: PropTypes.bool,
	onError: PropTypes.bool,
	errorMessage: PropTypes.string,
	style: PropTypes.object,
}

const StyledField = styled.fieldset`
  border: none;
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  margin: 0;
`

export default FormFieldSet
