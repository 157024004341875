import {CardNumberElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import {useContext, useEffect, useState} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {toast} from 'react-toastify'

import {organizationService, planService, subscriptionService} from '../api'
import CheckoutSkeleton from '../components/template/CheckoutSkeleton'
import {BillingForm, CardsForm, CheckoutForm} from '../components'
import CartWrapper from '../components/organisms/Cart/Wrapper'
import {UserContext} from '../components/Hooks/UserContext'
import {BtnSolid} from '../components/atoms/index.atoms'
import {CartContext} from '../context/index.context'
import useOrgId from '../components/Hooks/useOrgId'
import config from '../api/config'

import '../styles/checkout.scss'

const CheckoutPage = () => {
	const stripePromise = loadStripe( config.stripeClientSecret )
	return (
		<Elements stripe={ stripePromise }>
			<Checkout/>
		</Elements>
	)
}

const Checkout = () => {
	const { setCurrentOrganizations } = useContext( UserContext )
	const { cartProducts, setCartProducts } = useContext( CartContext )

	const [ step, setStep ] = useState( 1 )
	const [ cards, setCards ] = useState( [] )
	const [ promoCode, setPromoCode ] = useState( '' )
	const [ onLoading, setOnLoading ] = useState( true )
	const [ orderItems, setOrderItems ] = useState( [] )
	const [ billingData, setBillingData ] = useState( {} )
	const [ acceptTerms, setAcceptTerms ] = useState( false )
	const [ paymentMethod, setPaymentMethod ] = useState( null )
	const [ isDisabledBtn, setIsDisabledBtn ] = useState( false )

	const stripe = useStripe()
	const elements = useElements()
	const { handleSubmit, register, formState: { errors }, reset } = useForm()

	const history = useHistory()

	let orgId = useOrgId()

	const searchParams = new URLSearchParams( window.location.search )
	const queryPlan = searchParams.get( 'plan' )

	useEffect( () => {
		if(queryPlan){
			planService.details( queryPlan )
				.then( response => {
					setCartProducts( [ ...cartProducts, response ] )
				} )
				.catch( error => {
					console.error( error )
					toast.error( 'Error loading selected plan. Please return to the store. ' )
				} )
		}
	}, [queryPlan] )

	useEffect( () => {
		if ( orgId && step === 1 && !cards.length ) {
			organizationService.paymentMethods( orgId )
				.then( response => {
					if ( response.length ) {
						setCards( response )
						setPaymentMethod( response[ 0 ].id )
						setStep( 0 )
					}
				} )
				.then( () => {
					setOnLoading( false )
				} )
				.catch( error => {
					console.error( 'It was not possible to retrieve cards', error )
					setOnLoading( false )
				} )
		} else {
			setOnLoading( false )
		}

	}, [ orgId, step ] )

	const handleCheckout = async ( e ) => {
		e.preventDefault()

		if ( step === 0 ) {
			setOnLoading( true )

			subscriptionService.list( orgId )
				.then( response => {
					let subscriptionId
					if ( response ) {
						subscriptionId = response?.[ 0 ].id
					}
					return subscriptionId
				} )
				.then( async subscriptionId => {
					const prices = cartProducts.map( ( prod ) => prod.prices[ 0 ].id )
					await subscriptionService.update( subscriptionId, {
						prices,
						organization: orgId,
						default_payment_method: paymentMethod,
						coupon: promoCode,
					} )
				} )
				.then( () => {
					setOnLoading( false )
					setStep( 3 )
					setOrderItems( cartProducts )
					setCartProducts( [] )
				} )
				.catch( ( error ) => {
					setStep( 4 )
					setOnLoading( false )
					console.error( error )
				} )
		} else if ( step === 1 ) {
			setStep( 2 )
		} else if ( step === 2 ) {
			setIsDisabledBtn( true )
			const cardNum = elements.getElement( CardNumberElement )

			const { paymentMethod, error } = await stripe.createPaymentMethod( {
				type: 'card',
				card: cardNum,
				billing_details: {
					address: {
						city: billingData.city,
						line1: billingData.streetAddress,
						postal_code: billingData.zip,
						state: billingData.state,
					}
					,
					email: billingData.email,
					name: `${ billingData.firstName } ${ billingData.lastName }`,
					phone: billingData.phone,
				},
			} )

			if ( error ) {
				if ( error.type === 'validation_error' ) {
					setIsDisabledBtn( false )
					toast.error( error.message )
				} else {
					toast.error( error.message )
					setStep( 4 )
				}

				setOnLoading( false )
			} else {
				setOnLoading( true )
				let subscriptionId
				if ( !orgId ) {
					const organization = await organizationService.getOrCreate()
					orgId = organization.id
				} else {
					await subscriptionService.list( orgId )
						.then( response => {
							if ( response ) {
								subscriptionId = response?.[ 0 ]?.id
							}
						} )
				}

				organizationService
					.attachPaymentMethods( orgId, {
						payment_method: paymentMethod.id,
					} )
					.then( async () => {
						const prices = cartProducts.map( ( prod ) => prod.prices[ 0 ].id )
						if ( subscriptionId ) {
							await subscriptionService.update( subscriptionId, {
								prices,
								organization: orgId,
								billing_info: {
									address: {
										city: billingData.city,
										line1: billingData.streetAddress,
										postal_code: billingData.zip,
										state: billingData.state,
									}
									,
									email: billingData.email,
									name: `${ billingData.firstName } ${ billingData.lastName }`,
									phone: billingData.phone,
								},
								coupon: promoCode,
							} )
						} else {
							await subscriptionService.create( {
								prices,
								organization: orgId,
								billing_info: {
									address: {
										city: billingData.city,
										line1: billingData.streetAddress,
										postal_code: billingData.zip,
										state: billingData.state,
									}
									,
									email: billingData.email,
									name: `${ billingData.firstName } ${ billingData.lastName }`,
									phone: billingData.phone,
								},
								coupon: promoCode,
							} )
						}
					} )
					.then( async () => {
						await organizationService.listByUser()
							.then( organizations => {
								let organ = {}
								organizations.map( org => organ[ org.id ] = org )
								setCurrentOrganizations( organ )
							} )
					} )
					.then( () => {
						setOnLoading( false )
						setStep( 3 )
						setOrderItems( cartProducts )
						setCartProducts( [] )
					} )
					.catch( ( error ) => {
						setStep( 4 )
						setOnLoading( false )
						console.error( error )
					} )
			}
		}
	}

	const hdlSubmit = ( { city, email, firstName, lastName, phone, state, streetAddress, zip } ) => {
		setBillingData( {
			city,
			email,
			firstName,
			lastName,
			phone,
			state,
			streetAddress,
			zip,
		} )

		setStep( 2 )
	}

	useEffect( () => {
		if ( billingData ) {
			reset( billingData )
		}
	}, [ step ] )

	return (
		<main>
			<HeaderStyled>
				<h1>Checkout</h1>
				<span>Confirm your payment data</span>
			</HeaderStyled>

			{ onLoading
				? ( <CheckoutSkeleton/> )
				: (
					<>
						{ ( step === 0 || step === 1 || step === 2 ) && (
							<section>
								<BackButtonStyled onClick={ () => history.push( '/store' ) } type={ 'button' }>
									<FontAwesomeIcon icon={ faAngleLeft }/>
									Back to Store
								</BackButtonStyled>
								<FormStyled onSubmit={ step === 1
									? handleSubmit( hdlSubmit )
									: handleCheckout }>
									<Billing>
										{ step === 0 && <CardsForm onChange={ setPaymentMethod } onCancel={ () => {
											setStep( 1 )
										} } cards={ cards }/> }
										{ step === 1 && <BillingForm register={ register } errors={ errors }/> }
										{ step === 2 && (
											<>
												<MethodTitle>Payment method <BackButtonStyledPayment onClick={ () => {
													setStep( 1 )
												} } type={ 'button' }>
													Return to Billing Address
												</BackButtonStyledPayment></MethodTitle>
												<CheckoutForm billing={ {
													address: {
														city: billingData.city,
														line1: billingData.streetAddress,
														postal_code: billingData.zip,
														state: billingData.state,
													}
													,
													email: billingData.email,
													name: `${ billingData.firstName } ${ billingData.lastName }`,
													phone: billingData.phone,
												} }
																			onComplete={ setStep }
																			setAcceptTerms={ setAcceptTerms }
												/>
											</>
										) }
									</Billing>
									<CartWrapper
										isOpen={ true }
										btnType={ 'submit' }
										title={ 'Order review' }
										isDisabledBtn={ isDisabledBtn || ( step === 2 && !acceptTerms ) }
										btnName={ step === 0 || step === 2
											? ( cartProducts.reduce( ( total, { prices } ) => total + prices[ 0 ]?.days_free_trial, 0 ) === 0
												? 'Pay Now'
												: 'Start Trial' )
											: 'Go to checkout' }
										style={ { position: 'relative', top: 0, width: '100%', flex: '0 0 370px' } }
										coupon={ { promoCode, setPromoCode } }
									/>
								</FormStyled>
							</section>
						) }
						{ step === 3 && (
							<>
								<BackButtonStyled onClick={ () => history.push( '/store' ) } type={ 'button' }>
									<FontAwesomeIcon icon={ faAngleLeft }/>
									Back to Store
								</BackButtonStyled>
								<SuccessErrorStyled>
									<h2>Thank you for your order!</h2>
									<span>We received your payment for</span>
									{ orderItems.length > 0 && orderItems.map( item => <h4>{ item.name }</h4> ) }
									<span className="text is-light">Congratulations on starting your journey with TORCH.</span>
									<span className="text is-light mb-30">Please check your email, you will find a guide to get started and the your subscription detail.</span>

									<BtnSolid
										cta={ () => {
											window.location.assign('/')
										} }
										name={ 'OK' }
										className="one-third-width mt-30"
									/>
								</SuccessErrorStyled>
							</>
						) }
						{ step === 4 && (
							<>
								<BackButtonStyled onClick={ () => history.push( '/store' ) } type={ 'button' }>
									<FontAwesomeIcon icon={ faAngleLeft }/>
									Back to Store
								</BackButtonStyled>
								<SuccessErrorStyled>
									<h1>Oops...</h1>
									<p>
										<span>Error has occurred</span>
										<span>
									We are sorry for the inconvenience, there has been an error
								</span>
										<span> when creating your subscription</span>
									</p>
									<BtnSolid
										cta={ () => {
											history.push( `/store/${ orgId ?? '' }` )
										} }
										name={ 'Back to Store' }
										className="mt-30 one-third-width"
									/>
								</SuccessErrorStyled>
							</> ) }
					</> ) }
		</main>
	)
}

const BackButtonStyled = styled.button`
  cursor: pointer;
  margin-top: 1rem;
  margin-left: 8rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  color: var(--orange-btn);
  border: 1px solid var(--border);
  border-radius: 0 1.5rem 1.5rem 0;
  background-color: var(--card-bg);

  & svg {
    margin-right: 0.25rem;

    & path {
      fill: var(--orange-btn) !important;
    }
  }
`
const BackButtonStyledPayment = styled.button`
  cursor: pointer;
  margin-top: 1rem;
  margin-left: 13.7rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  color: var(--orange-btn);
  border: 1px solid var(--border);
  border-radius: 4px;
  background-color: var(--card-bg);

  & svg {
    margin-right: 0.25rem;

    & path {
      fill: var(--orange-btn) !important;
    }
  }
`
const HeaderStyled = styled.header`
  display: flex;
  padding: 0.5rem 2rem;
  align-items: center;
  background-color: #fff;
  /* justify-content: center; */

  & h1,
  & span {
    margin: 0;
    padding: 0;
  }

  & h1 {
    font-size: 2rem;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    border-right: solid 1px var(--border);
  }
`

const FormStyled = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 40px auto 0;
  align-items: flex-start;
  justify-content: space-between;

  & > div {
    padding: 16px 16px 24px;
  }
`

const Billing = styled.div`
  flex: 1 1 570px;
  max-width: 570px;
  background: #fff;
`

const MethodTitle = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
`

const SuccessErrorStyled = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 70%;
  background: #fff;
  box-shadow: 2px 2px 6px rgba(196, 196, 196, 0.8);
  border-radius: 8px;
  margin: auto;
  padding: 4rem 0;

  & h1 {
    margin: 0;
  }

  & p {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
  }
`

export default CheckoutPage
