import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

// Containers
import LoginCard from '../containers/Login/LoginCard'
import TwoFACard from '../containers/Login/2FACard'

// API
import { profileService } from '../api'

function Login() {
  const [stage, setStage] = useState("login")
  const [flow_props, setProps] = useState({})

  function changeStage(newstage, new_props) {
    setProps(new_props)
    setStage(newstage)
  }

  function handleBasicInfo(user_id) {
    profileService.retrieve(user_id)
      .then(user => {
        setStage("logged")
        console.log("user", user)
      }).catch(error => {
        console.error("Error loading user data", error)
      })
  }

  let card
  if (stage === "login") {
    card = <LoginCard onChange={changeStage} />
  } else if (stage === "2fa") {
    card = <TwoFACard onChange={changeStage} login_info={flow_props} />
  } else if (stage === "basic_user_info") {
    handleBasicInfo(flow_props.user_id)
  } else if (stage === "logged") {

    let searchParams = new URLSearchParams(window.location.search)
    let hasQueryParam = searchParams.has('redirect')

    if (hasQueryParam) {
      let queryParam = decodeURIComponent(searchParams.get('redirect'))
      let redirectUrl = new URL(queryParam)
      if(redirectUrl.hostname.endsWith("echoanalyticsgroup.com")){
        window.location.assign(queryParam)
        return
      }
    } 
    return <Redirect to={`/home`} />
  }

  return (
    <div className="row">
      {card}
    </div>
  )
}

export default Login
