import {CardNumberElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
import {useContext, useEffect, useState} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'

import CheckoutSkeleton from '../../components/template/CheckoutSkeleton'
import {organizationService} from '../../api'
import RegisterForm from '../../components/Checkout/RegisterForm'
import CartWrapper from '../../components/organisms/Cart/Wrapper'
import {BtnSolid} from '../../components/atoms/index.atoms'
import {BillingForm, CheckoutForm} from '../../components'
import {CartContext} from '../../context/index.context'
import useOrgId from '../../components/Hooks/useOrgId'
import config from '../../api/config'

import '../../styles/checkout.scss'
import axios from 'axios'
import {toast} from 'react-toastify'
import Modal from '../../components/Elements/Modal'

const ExternalCheckoutPage = () => {
	const stripePromise = loadStripe( config.stripeClientSecret )
	return ( <Elements stripe={ stripePromise }>
		<Checkout/>
	</Elements> )
}

const Checkout = () => {
	const { cartProducts, setCartProducts } = useContext( CartContext )

	const [ step, setStep ] = useState( 0 )
	const [ cards, setCards ] = useState( [] )
	const [ promoCode, setPromoCode ] = useState( '' )
	const [ onLoading, setOnLoading ] = useState( true )
	const [ billingData, setBillingData ] = useState( {} )
	const [ acceptTerms, setAcceptTerms ] = useState( false )
	const [ paymentMethod, setPaymentMethod ] = useState( null )
	const [ isDisabledBtn, setIsDisabledBtn ] = useState( false )
	const [ showUserExistModal, setShowUserExistModal ] = useState( false )
	const [ paymentIntentData, setPaymentIntentData ] = useState(
		{ trial: false, secret: '' } )

	const stripe = useStripe()
	const elements = useElements()
	const {
		handleSubmit,
		register,
		formState: { errors },
		setError,
		getValues,
		watch,
		clearErrors,
		reset,
	} = useForm()

	let orgId = useOrgId()

	useEffect( () => {

		if ( orgId && step === 1 && !cards.length ) {
			organizationService.paymentMethods( orgId ).then( response => {
				if ( response.length ) {
					setCards( response )
					setPaymentMethod( response[ 0 ].id )
					setStep( 0 )
				}
			} ).then( () => {
				setOnLoading( false )
			} ).catch( error => {
				console.error( 'It was not possible to retrieve cards', error )
				setOnLoading( false )
			} )
		} else {
			setOnLoading( false )
		}

	}, [ orgId, step ] )

	const hdlRegister = async ( {
		alias,
		dateBirth,
		email,
		firstName,
		lastName,
		middleName,
		password,
		phoneNumber,
		profileType,
	} ) => {
		setIsDisabledBtn( true )
		try {
			const {
				data: {
					client_secret, pending_setup_intent_client_secret,
				},
			} = await axios.post( `/p-subscription/`, {
				'credentials': {
					'username': email,
					'alias': alias,
					'first_name': firstName,
					'last_name': lastName,
					'middle_name': middleName,
					'password': password,
					'phone_number': phoneNumber,
					'email': email,
					'dob': dateBirth,
					'private': profileType === 'private',
				}, 'prices': [ cartProducts[ 0 ]?.prices[ 0 ]?.id ],

			}, {
				headers: {
					'Authorization': config.externalApiKey,
				},
			} )

			if ( client_secret ) {
				setPaymentIntentData( { trial: false, secret: client_secret } )
				setStep( 1 )
			} else if ( pending_setup_intent_client_secret ) {
				setPaymentIntentData(
					{ trial: true, secret: pending_setup_intent_client_secret } )
				setStep( 1 )
			} else {
				toast.error( 'It was not possible to create a payment intent' )
			}
			toast( 'User created successfully' )
		} catch ( { response } ) {

			if ( response?.data?.credentials?.username || response?.data.toString().includes( 'email already exists' ) ) {
				setShowUserExistModal( true )
			}
			if ( response?.data?.toString().includes( 'password policy' ) ) {
				setError( 'password',
					{ message: 'Passwords must be 8 characters minimum (upper case and lower case letters).' } )
				setError( 'confirmPassword',
					{ message: 'Passwords must be 8 characters minimum (upper case and lower case letters).' } )
			}

			console.log( response )
		} finally {
			setIsDisabledBtn( false )
		}

	}

	const handleCheckout = async ( e ) => {
		e.preventDefault()

		if ( step === 1 ) {
			setStep( 2 )
		}

		if ( step === 2 ) {
			setIsDisabledBtn( true )
			const cardNum = elements.getElement( CardNumberElement )

			if ( paymentIntentData.trial ) {
				const { setupIntent, error } = await stripe.confirmCardSetup(
					paymentIntentData.secret,
					{
						payment_method: {
							card: cardNum,
							billing_details: {
								address: {
									city: billingData.city,
									line1: billingData.streetAddress,
									postal_code: billingData.zip,
									state: billingData.state,
								}
								,
								email: billingData.email,
								name: `${ billingData.firstName } ${ billingData.lastName }`,
								phone: billingData.phone,
							},
						},
					} )

				if ( error ) {
					if ( error.type === 'validation_error' ) {
						setIsDisabledBtn( false )
						toast.error( error.message )
					} else {
						toast.error( error.message )
						setStep( 4 )
					}

					setOnLoading( false )
				} else {
					setOnLoading( false )
					setStep( 3 )
					setCartProducts( [] )
				}
			} else {
				const { paymentIntent, error } = await stripe.confirmCardPayment(
					paymentIntentData.secret, {
						payment_method: {
							card: cardNum, billing_details: {
								address: {
									city: billingData.city,
									line1: billingData.streetAddress,
									postal_code: billingData.zip,
									state: billingData.state,
								}
								,
								email: billingData.email,
								name: `${ billingData.firstName } ${ billingData.lastName }`,
								phone: billingData.phone,
							},
						},
					} )

				if ( error ) {
					if ( error.type === 'validation_error' ) {
						setIsDisabledBtn( false )
						toast.error( error.message )
					} else {
						toast.error( error.message )
						setStep( 4 )
					}

					setOnLoading( false )
				} else {
					setOnLoading( false )
					setStep( 3 )
					setCartProducts( [] )
				}
			}

		}
	}

	const hdlSubmit = ( { city, email, firstName, lastName, phone, state, streetAddress, zip } ) => {
		setBillingData( {
			city,
			email,
			firstName,
			lastName,
			phone,
			state,
			streetAddress,
			zip,
		} )

		setStep( 2 )
	}

	useEffect( () => {
		if ( billingData ) {
			reset( billingData )
		}
	}, [ step ] )

	return <>
		<main>
			{ ( step === 0 || step === 1 || step === 2 ) && ( <HeaderStyled>
				<h1>Checkout</h1>
				<span>Confirm your payment data</span>
			</HeaderStyled> ) }
			{ onLoading
				? ( <CheckoutSkeleton/> )
				: ( <>
					{ ( step === 0 || step === 1 || step === 2 ) && ( <section>
						<FormStyled
							onSubmit={ step === 0 || step === 1
								? handleSubmit( step === 0
									? hdlRegister
									: hdlSubmit )
								: handleCheckout }>
							<Billing>
								{ step === 0 && <RegisterForm
									register={ register }
									errors={ errors }
									setError={ setError }
									getValues={ getValues }
									watch={ watch }
									clearErrors={ clearErrors }
								/> }
								{ step === 1 && <BillingForm register={ register } errors={ errors }/> }
								{ step === 2 && ( <>
									<MethodTitle>Payment method</MethodTitle>
									<CheckoutForm billing={ {
										address: {
											city: billingData.city,
											line1: billingData.streetAddress,
											postal_code: billingData.zip,
											state: billingData.state,
										}
										,
										email: billingData.email,
										name: `${ billingData.firstName } ${ billingData.lastName }`,
										phone: billingData.phone,
									} }
																setAcceptTerms={ setAcceptTerms }
																onComplete={ setStep }/>
								</> ) }
							</Billing>
							<CartWrapper
								isOpen={ true }
								btnType={ 'submit' }
								title={ 'Order review' }
								isDisabledBtn={ isDisabledBtn || ( step === 2 && !acceptTerms ) }
								showPromoCode={ step === 1 || step === 2 }
								btnName={ step === 0
									? 'Next'
									: step === 1
										? 'Go to checkout'
										: 'Pay Now' }
								style={ {
									position: 'relative',
									top: 0,
									width: '100%',
									flex: '0 0 370px',
								} }
								coupon={ { promoCode, setPromoCode } }
							/>
						</FormStyled>
					</section> ) }
					{ step === 3 && ( <SuccessErrorStyled>
						<h1>Great</h1>
						<p>
							<span>You subscription has been successfully created.</span>
							<span>You can go to the product you bought now.</span>
						</p>
						<StyledLink to={ '/' }>Let's go</StyledLink>
					</SuccessErrorStyled> ) }
					{ step === 4 && ( <SuccessErrorStyled>
						<h1>Oops...</h1>
						<p>
							<span>Error has occurred</span>
							<span>
									We are sorry for the inconvenience, there has been an error when creating your
									subscription
								</span>
						</p>
						<StyledLink to={ '/external/store' }>Back to Store</StyledLink>
					</SuccessErrorStyled> ) }
				</> ) }
		</main>
		<Modal isOpen={ showUserExistModal }
					 handleClose={ () => setShowUserExistModal( false ) }>

			<h1 className={ 'title is-dark mx-5 mb-15' }
					style={ { textAlign: 'center' } }>User already exist</h1>
			<p className={ 'text is-light mx-5 mb-15' } style={ { textAlign: 'center' } }>
				<span>You have already created an account with this email address.</span>
			</p>
			<nav style={ {
				display: 'flex', justifyContent: 'space-between', alignItems: 'center',
			} }>
				<Link to={ '/login' }>
					<a style={ { color: 'var(--link)', cursor: 'pointer' } }>Go to Login</a>
				</Link>
				<BtnSolid
					cta={ () => {
						setShowUserExistModal( false )
					} }
					name={ 'Try again' }
				/>
			</nav>
		</Modal>
	</>
}

const HeaderStyled = styled.header`
  display: flex;
  padding: 0.5rem 2rem;
  align-items: center;
  background-color: #fff;

  & h1,
  & span {
    margin: 0;
    padding: 0;
  }

  & h1 {
    font-size: 2rem;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    border-right: solid 1px var(--border);
  }
`

const FormStyled = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 40px auto 0;
  align-items: flex-start;
  justify-content: space-between;

  & > div {
    padding: 16px 16px 24px;
  }
`

const Billing = styled.div`
  flex: 1 1 620px;
  max-width: 620px;
  background: #fff;
  border: 1px solid var(--border);
`

const MethodTitle = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
`

const SuccessErrorStyled = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  & h1 {
    margin: 0;
  }

  & p {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
  }
`

const StyledLink = styled( Link )`
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  min-width: 9rem;
  border-width: 1px;
  text-align: center;
  border-style: solid;
  border-radius: 0.25rem;
  padding: 0.5rem 1.25rem;
  border-color: var(--orange-btn);
  transition: all 500ms ease-in-out;
  background-color: var(--orange-btn);


  :hover {
    border-color: #d47810;
    background-color: #d47810;
  }
`

export default ExternalCheckoutPage
