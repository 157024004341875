import React, {useState} from 'react'
import {Redirect} from 'react-router-dom'

// Components
import SendCode from '../containers/RecoverPassword/SendCode'
import ChangePassword from '../containers/RecoverPassword/ChangePassword'

function RecoverPassword() {
    const [stage, setStage] = useState("send_code")
    const [flow_props, setProps] = useState({})

    function changeStage(newstage, new_props){
        setProps(new_props)
        setStage(newstage)
    }

    var card
    if (stage === "send_code"){
        card = <SendCode onChange={changeStage} />
    }
    else if (stage === "change_password"){
        card = <ChangePassword onChange={changeStage} recover_info={flow_props} />
    } 
    else if (stage === "login") {
        return <Redirect to="./login" />
    } 

    return (
        <div className="row">
            {card}
        </div>
    )
}

export default RecoverPassword