import React from 'react'

// Components
import Title from '../../components/Elements/Title'
import TwoFAForm from '../../components/RegistrationForms/2FAForm'

function TwoFACard(props) {
    return (
        <div className="regist-card col-4 screen-center" >
            <div className="center">
                <Title text={props.login_info.method === 'SOFTWARE_TOKEN_MFA' ? '2FA Authenticator App' : "2FA SMS Verification"} />
            </div>
            <TwoFAForm login_info={props.login_info} onChange={props.onChange}/>
        </div>
    )
}

export default TwoFACard