import React from 'react'

// Assets
import EchoLogo from '../../assets/img/echo-logo.png'

// Components
import Title from '../../components/Elements/Title'
import ChangePasswordForm from '../../components/RegistrationForms/ChangePasswordForm'

function ChangePassword(props) {
    return (
        <div className="regist-card col-4 screen-center">
            <div className="center">
                <img src={EchoLogo} alt="logo" />
                <Title text="Recover Password" />
                <h2 className="subheading is-light">Please enter your new password</h2>
            </div>
            <ChangePasswordForm onChange={props.onChange} recover_info={props.recover_info} />
        </div>
    )
}

export default ChangePassword
