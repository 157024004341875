import { ToastContainer } from 'react-toastify'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

import 'react-toastify/dist/ReactToastify.css'

import CommonRouter from './router/CommonRouter'
import config from './api/config'

import './styles/grid/simple-grid.min.css'
import './styles/variables.scss'
import './styles/main.scss'

axios.defaults.withCredentials = true
axios.defaults.baseURL = config.apiUrl

ReactDOM.render(
	<StrictMode>
		<CommonRouter />
		<ToastContainer
			draggable={true}
			autoClose={2000}
			theme={'colored'}
			closeOnClick={true}
			pauseOnHover={true}
			hideProgressBar={true}
			position={'bottom-right'}
			toastStyle={{ backgroundColor: "#F19126" }}
		/>
	</StrictMode>,
	document.getElementById('root')
)
