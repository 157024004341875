// eslint-disable-next-line no-unused-vars
import React from 'react'
import { useParams } from 'react-router-dom'

const GetOrgId = () => {
  let { org_id } = useParams()
  return org_id
}

export default GetOrgId
