import styled from 'styled-components'

const CheckoutSkeleton = () => (
	<LoaderStyled>
		<article className={'skeleton-animation'}>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
				<strong></strong>
				<span></span>
			</div>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
				<strong></strong>
				<span></span>
			</div>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<div>
				<strong></strong>
				<span></span>
			</div>
		</article>
		<aside className={'skeleton-animation'}>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<div>
				<strong></strong>
				<span></span>
			</div>
			<button></button>
		</aside>
	</LoaderStyled>
)

const LoaderStyled = styled.section`
	height: 80vh;
	display: grid;
	column-gap: 5rem;
	max-width: 1024px;
	margin: 40px auto 0;
	grid-template-columns: 1fr 0.65fr;

	& aside,
	& article {
		background-color: var(--skeleton-bg);
	}

	& article {
		height: 100%;
		padding: 2rem;
		column-gap: 2rem;
		display: inline-grid;
		grid-template-columns: repeat(2, 1fr);

		& div {
			display: flex;
			height: 3.5rem;
			flex-direction: column;
			justify-content: space-between;

			& strong,
			& span {
				background-color: rgb(230, 230, 230);
			}

			& strong {
				width: 50%;
				height: 0.75rem;
			}

			& span {
				width: 100%;
				height: 2rem;
			}
		}
	}
	& aside {
		display: grid;
		padding: 2rem;
		column-gap: 2rem;
		height: fit-content;

		& button {
			width: 100%;
			border: none;
			height: 2rem;
			margin-top: 1rem;
			justify-self: center;
			background-color: rgb(230, 230, 230);
		}

		& div {
			display: flex;
			padding-bottom: 1rem;
			justify-content: space-between;
			grid-template-columns: repeat(2, 1fr);

			& strong,
			& span {
				height: 1rem;
				background-color: rgb(230, 230, 230);
			}

			& strong {
				width: 50%;
			}

			& span {
				width: 20%;
			}
		}
	}
`

export default CheckoutSkeleton
