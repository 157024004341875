import { Link, useHistory } from 'react-router-dom'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import axios from 'axios'

import { BtnOutline, BtnSolid, FormInput, Title } from '../components/atoms/index.atoms'
import Modal from '../components/Elements/Modal'
import { FormFieldSet } from '../components/molecules/index.molecules'
import { HeaderWrapper } from '../components/organisms/index.organisms'
import { CartContext } from '../context/index.context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import useOrgId from '../components/Hooks/useOrgId'

const EnterpriseForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const { setCartProducts } = useContext(CartContext)

	const [onLoading, setOnLoading] = useState(false)
	const [showTrialModal, setShowTrialModal] = useState(false)
	const [showErrorModal, setShowErrorModal] = useState(false)

	const history = useHistory()
	const orgId = useOrgId()

	const hdlSubmit = async (formData) => {
		try {
			const { data } = await axios.post('/subscription/enterprise_request/', {
				product: 'Enterprise',
				...formData,
			})

			setCartProducts([data])
			setShowTrialModal(true)
		} catch ({ message }) {
			toast.error(message)
			setShowErrorModal(true)
		} finally {
			setTimeout(() => {
				setOnLoading(false)
			}, 500)
		}
	}

	return (
		<>
			<HeaderWrapper />
			<StyledSection>
				<Title text='Account Information' />
				<StyledForm onSubmit={handleSubmit(hdlSubmit)}>
					<FormFieldSet
						name={'Name'}
						isRequired={true}
						onErr={errors.name}
						errMsg={'Name is required'}
						style={{ gridColumnStart: 1, gridColumnEnd: 4 }}
					>
						<FormInput
							onErr={errors.name}
							customAttributes={{ ...register('name', { required: true, validate: (value) => { return !!value.trim()} }) }}
							/>
					</FormFieldSet>

					<FormFieldSet
						name={'Title'}
						isRequired={true}
						onErr={errors.title}
						errMsg={'Title is required'}
						style={{ gridColumnStart: 4, gridColumnEnd: 7 }}
					>
						<FormInput
							onErr={errors.title}
							customAttributes={{ ...register('title', { required: true, validate: (value) => { return !!value.trim()} }) }}
						/>
					</FormFieldSet>

					<FormFieldSet
						name={'Email'}
						isRequired={true}
						onErr={errors.email}
						errMsg={'Email is required'}
						style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
						>
						<FormInput
						onErr={errors.email}
							customAttributes={{ ...register('email', { required: true, validate: (value) => { return !!value.trim()} }) }}
							type='email'

						/>
					</FormFieldSet>
					<FormFieldSet
						name={'Phone'}
						isRequired={true}
						onErr={errors.phone}
						errMsg={errors?.phone?.type === 'pattern' ? 'Phone format: +15555555 ' : 'Phone is required'}
						style={{ gridColumnStart: 3, gridColumnEnd: 5 }}
						>
						<FormInput
							onErr={errors.phone}
							customAttributes={{ ...register('phone', { required: true, validate: (value) => { return !!value.trim()}, pattern: /[+][0-9]/ }) }}
							type='tel'

						/>
					</FormFieldSet>
					<FormFieldSet
						isRequired={true}
						name={'Company Size'}
						errMsg={'Company size is required'}
						onErr={errors.company_size}
						style={{ gridColumnStart: 5, gridColumnEnd: 7 }}
					>
						<FormInput
							onErr={errors.company_size}
							customAttributes={{ ...register('company_size', { required: true, validate: (value) => { return !!value.trim()} }) }}

						/>
					</FormFieldSet>
					<FormFieldSet
						name={'Company Information'}
						errMsg={'Company information is required'}
						onErr={errors.company_information}
						style={{ gridColumnStart: 1, gridColumnEnd: 7 }}
						isRequired={true}
					>
						<FormInput
							onErr={errors.company_information}
							customAttributes={{ ...register('company_information', { required: true, validate: (value) => { return !!value.trim()} }) }}

						/>
					</FormFieldSet>
					<FormFieldSet
						name={'Description'}
						onErr={errors.needs}
						errMsg={'Description is required'}
						style={{ gridColumnStart: 1, gridColumnEnd: 7 }}
						isRequired={true}
					>
						<FormInput
							onErr={errors.needs}
							customAttributes={{ ...register('needs', { required: true, validate: (value) => { return !!value.trim()} }) }}
						/>
					</FormFieldSet>
					<StyledFooter>
						<BtnOutline name='Back' cta={() => history.push('/store')} type='button' />
						<BtnSolid name='Continue' type='submit' onLoad={onLoading} isDisabled={onLoading} />
					</StyledFooter>
				</StyledForm>
			</StyledSection>
			<Modal
				isOpen={showTrialModal}
				handleClose={() => {
					setShowTrialModal(false)
				}}
			>
				<div>
					<h1
						style={{
							margin: 0,
							fontSize: '1.5rem',
							fontWeight: 'bold',
							fontStyle: 'normal',
							paddingBottom: '1rem',
							color: 'var(--main-text)',
							fontFamily: 'var(--main-font)',
							borderBottom: '1px solid #ececec',
						}}
					>
						Enterprise Plan Request
					</h1>
					<p className='text is-light is-bold left'>
						We are processing your request. Meanwhile we offer you an one week free trial
					</p>
					<footer
						style={{
							display: 'flex',
							marginTop: '3rem',
							alignItems: 'center',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<Link to={'/store'} title={'Maybe later'}>
							Maybe later
						</Link>
						<BtnSolid name={'Try Free Trial'} cta={() => history.push('/checkout')} />
					</footer>
				</div>
			</Modal>
			<Modal
				isOpen={showErrorModal}
				handleClose={() => {
					setShowErrorModal(false)
				}}
			>
				<div>
					<section>
						<h1>Oops...</h1>
						<p style={{ textAlign: 'center', padding: '.5rem 1rem 1rem 1rem' }}>
							Sorry for the inconvenience, an error occurred while sending your form.
						</p>
					</section>
					<footer
						style={{ display: 'grid', gridAutoFlow: 'column', justifyContent: 'space-between' }}
					>
						<StyledBtnOutline to={`/store/${orgId ?? ''}`}>
							<FontAwesomeIcon icon={faAngleLeft} />
							Back to Store
						</StyledBtnOutline>
						<StyledBtnSolid to={'/'}>Go Home</StyledBtnSolid>
					</footer>
				</div>
			</Modal>
		</>
	)
}

const StyledBtnSolid = styled(Link)`
	color: #fff;
	cursor: pointer;
	font-size: 1rem;
	min-width: 9rem;
	text-align: center;
	border-width: 1px;
	border-style: solid;
	border-radius: 0.25rem;
	padding: 0.5rem 1.25rem;
	transition: all 500ms ease-in-out;
	border-color: #f69221;
	background-color: #f69221;

	:hover {
		border-color: #d47810;
		background-color: #d47810;
	}
`

const StyledBtnOutline = styled(Link)`
	color: #fff;
	cursor: pointer;
	font-size: 1rem;
	min-width: 9rem;
	text-align: center;
	color: #f69221;
	border-width: 1px;
	border-style: solid;
	border-radius: 0.25rem;
	padding: 0.5rem 1.25rem;
	border-color: #f69221;
	background-color: transparent;
	transition: all 500ms ease-in-out;

	& svg {
		& path {
			fill: #f69221 !important;
		}
		margin-right: 0.5rem;
	}

	:hover {
		border-color: #d47810;
	}
`

const StyledSection = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const StyledForm = styled.form`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 1.5rem;
	width: 50%;
`

const StyledFooter = styled.footer`
	width: 100%;
	display: flex;
	margin-top: 2.5rem;
	grid-column-end: 7;
	flex-direction: row;
	grid-column-start: 1;
	justify-content: space-between;
`

export default EnterpriseForm
