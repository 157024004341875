import React, { useState, useEffect } from 'react'
import styled from 'styled-components'


const CardsForm = (props) => {
	const { onChange, onCancel, cards } = props
	const [selectedCard, setSelectedCard] = useState(null)

	function handleChange(e){
		setSelectedCard(e.currentTarget.getAttribute('id'))
		onChange(e.currentTarget.getAttribute('id'))
	}

	return (
		<div>
			<Title>Payment Methods</Title>
				<p className='text is-light is-bold left'>Select a payment method <span className='text is-orange cursor is-link left' onClick={onCancel}> or create a new one</span></p> 
				<table className="table" cellSpacing="0">
					<thead>
					<tr className="table-header flexer mt-15">
						<th className="text is-light full-width left side-margin-0">Card</th>
						<th className="text is-light full-width left side-margin-0">Type</th>
						<th className="text is-light full-width left side-margin-0">Exp. Date</th>
						<th className="text is-light full-width left side-margin-0"></th>
					</tr>
					</thead>
					<tbody>
						{cards.map(card => (
							<tr className="table-rows flexer cursor" id={card.id} onClick={handleChange}>
								<td className="text is-light full-width left side-margin-0">...{card.last4}</td>
								<td className="text is-light full-width left side-margin-0">{card.brand.toUpperCase()}</td>
								<td className="text is-light full-width left side-margin-0">{card.exp_month}/{card.exp_year}</td>
								<td className="text flexer-1 left side-margin-0 cursor">
									{ selectedCard === card.id && <i className="fa fa-check mr-10 is-light"></i>}
								</td>
							</tr>
						))}
					</tbody>
				</table>
		</div>
	)
}

const Form = styled.section`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 1.5rem;
`

const Title = styled.h2`
	margin: 0 0 20px;
	font-size: 16px;
`

export default CardsForm
