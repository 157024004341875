import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../../Elements/Button";
import { FormInput } from "../../atoms/index.atoms";
import { FormFieldSet } from "../../molecules/index.molecules";
import styled from "styled-components";

const RegisterStepForm = ({
  currentMethod,
  onSubmit,
  serverErrors,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  useEffect(() => {
    if (serverErrors.non_field_errors) {
      setError("code", {
        message: "Invalid verification code provided, please try again.",
      });
    }
  }, [serverErrors]);

  return (
    <section
      className="center"
      style={{
        height: "70%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className="half-width">
        <p className="title-2 is-dark is-bold">
          {" "}
          {currentMethod === "SMS"
            ? "Enter SMS Code"
            : "Enter Authenticator App Code"}
        </p>

        <p className="text is-light my-30">
          {currentMethod === "SMS"
            ? "Please enter the code we sent to your phone to confirm"
            : "Please enter the code generated by the Authenticator App to confirm"}
        </p>

        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormFieldSet
            className="full-width"
            onErr={errors.code}
            errMsg={errors?.code?.message}
            name={
              currentMethod === "APP" ? "Authenticator App Code" : "SMS Code"
            }
          >
            <FormInput
              onErr={errors.code}
              customAttributes={{
                ...register("code", {
                  required: { value: true, message: "This field is required" },
                }),
              }}
            />
          </FormFieldSet>

          <Button
            text="Continue"
            class="mt-30 orange-btn half-width subheading-light"
            type="submit"
          />
        </StyledForm>
      </div>
    </section>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
`;

export default RegisterStepForm;
