import React, { useState } from 'react'

// Hooks

// Components
import Button from '../../Elements/Button'
import InputField from '../../Elements/InputField'

// API
import { userService } from '../../../api'

function VerifyAttribute(props) {
    const [errors, setErrors] = useState({})

    function handleSubmit(e){
        e.preventDefault()

        var form_element = document.getElementById(`verify-${props.name}-form`)
        var form_data = new FormData(form_element)

        var body = {}
        form_data.forEach((value, key) => {
            if (value) {
                body[key] = value
            }
        })

        userService.verify_attribute(body)
        .then(()=>{
            props.onVerification(props.name)
        })
        .catch(error=>{
            console.error("It was not possible to verify attribute", error)
            setErrors(error)
        })

    }

    return (
        <form id={`verify-${props.name}-form`} onSubmit={handleSubmit} className="mx-30 exp-rev-section">
            <input type="hidden" name="AttributeName" value={props.name}/>
            <p className="subtitle is-dark mt-60">{`Verify ${props.displayName}`}</p>
            <InputField name="Code" placeholder="Enter code" class="input-field" required={true} error={errors.Code || errors.AttributeName || errors.non_field_errors} />
            <div className="center center-mobile">
                <Button text="Verify" class="orange-btn mt-30 subheading is-white" type="submit"/>
            </div>
        </form>
    )
}

export default VerifyAttribute
