import Button from "../../Elements/Button";

const VerifiedStepForm = ({ onNext, currentMethod }) => {
  const handleComplete = () => {
    onNext();
  };

  return (
    <>
      <section
        className="center"
        style={{
          height: "70%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="half-width">
          <i className="fa fa-check-circle-o fa-3x is-orange" />
          <p className="title-2 is-bold">
            {currentMethod === "SMS"
              ? "SMS Authentication is Complete"
              : "Authenticator App Setup is Complete"}
          </p>
          <p className="text is-light line-margin-0">
            {currentMethod === "SMS"
              ? "Your account login is secured using your phone number and you will receive a 2FA code each time you log in."
              : "Your account login is secured using your Authenticator App.  You will need to provide the current code each time you log in."}
          </p>
          <Button
            text="OK"
            class="mt-30 orange-btn subheading-light one-third-width"
            type="button"
            onClick={handleComplete}
          />
        </div>
      </section>
    </>
  );
};

export default VerifiedStepForm;
