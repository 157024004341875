import {useContext} from 'react'
import GetOrgId  from '../../components/Elements/GetOrgId'

import { UserContext } from './UserContext'
import { checkPermissions }  from '../../permissions-validation/checkPermissions'


export default function usePermissions(
  requiredPermissions,
  checkAll,
  objectId,
) {
  const { currentUser, currentOrganizations } = useContext(UserContext)
  const orgId = GetOrgId()

  if (orgId) {
    return checkPermissions(currentUser.app_permission_policy?.policy ? currentUser.app_permission_policy.policy : {}, 
      requiredPermissions, checkAll, objectId) || 
    checkPermissions(currentOrganizations[orgId].policy?.policy ? currentOrganizations[orgId].policy.policy : {}, 
      requiredPermissions, checkAll, objectId)
  }

  return checkPermissions(currentUser.app_permission_policy?.policy ? currentUser.app_permission_policy.policy : {}, 
    requiredPermissions, checkAll, objectId)

}