import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconSingle = ({ icon, name, cta, className, style }) => (
	<FontAwesomeIcon
		title={name}
		icon={icon}
		name={name}
		onClick={cta}
		style={style}
		className={className}
	/>
)

export default IconSingle
