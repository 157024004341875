import { useContext, useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { config } from '../api/index.api'
import { mixpanelService } from '../api/mixpanel-service'
import logoToolkit from '../assets/img/toolkitLogo.png'
import logoCaseManager from '../assets/img/CaseManagerLogo.png'
import logoAcademy from '../assets/img/logo_academy.png'
import torchMainLogo from '../assets/img/torchMainLogo.png'
import { BtnSolid } from '../components/atoms/index.atoms'
import { UserContext } from '../components/Hooks/UserContext'
import { HeaderWrapper } from '../components/organisms/index.organisms'
//import PermissionWrapper from '../components/Permissions/PermissionWrapper'
import usePermissions from '../components/Hooks/usePermissions'
import {toast} from 'react-toastify'
import {userService} from '../api'

const LandingPage = () => {
	const { currentOrganizations } = useContext(UserContext)
	const history = useHistory()
	const [goToLMS, setGoToLMS]=useState(false)

	const navStyle = {
		display: 'flex',
		minHeight: '70vh',
		flexWrap: 'nowrap',
		justifyContent: 'center',
	};

	const blueButton = {
		backgroundColor:  'var(--blue-btn)',
		 borderColor:  'var(--blue-btn)'
	};
	const blackButton = {
		backgroundColor:  'var(--black-btn)',
		 borderColor:  'var(--black-btn)'
	};
	const productFigureStyle = {
		alignItems: 'center',
		justifyContent: 'center',
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column',
		rowGap: '0.1rem',
	};

	const rowStyle = {
		display: 'flex',
		flexDirection: 'row',
	};

	const mainLogoFigureStyle = {
		alignItems: 'center',
		justifyContent: 'center',
		padding: '1rem',
		display: 'flex',
		flexDirection: 'row',
	};

	const iconLogoFigureStyle = {
		width: '6rem', 
		height: '6rem',
		 margin:'25px' 
	};

	useEffect(() => {
		if (currentOrganizations && Object.keys(currentOrganizations).length === 0) {
			history.push('/store')
		}
	}, [currentOrganizations, history])
	
	function handleAcademy() {
		setGoToLMS(true)
		userService.get_lms_url().then((res) => {
			window.open(res.url) 
			setGoToLMS(false)
		})		
	}

	function handleToolkit() {
		// redirect to subdomain
		const link = `${config.cid}/tools`
		mixpanelService.HomePageOpen('CID')
		window.location.assign(link)
	}

	function handleCaseManager() {
		// redirect to subdomain
		const link = `${config.torch}/organizations`
		mixpanelService.HomePageOpen('Torch')
		window.location.assign(link)
	}
	
	function sendStore(){
		toast("Please go to the store to purchase a subscription")
		//redirect if needed
	}
	return (
		<>
			<HeaderWrapper />
			<nav style={navStyle}>
				<div display='inline-block'>
					<figure style={mainLogoFigureStyle}>
						<img src={torchMainLogo} alt='Torch Logo' style={{ height: '11rem' }} />
					</figure>
				<div  style={rowStyle}>
					<div  className="column" >
						<figure style={productFigureStyle}>
							<img src={logoAcademy} alt='Academy Logo' style={iconLogoFigureStyle}/>
							<BtnSolid name='Academy' isDisabled={goToLMS} type='button' cta={usePermissions('torch::web_access',false) ? () => handleAcademy() : () => sendStore()} style={blackButton} />
						</figure>
					</div>
					<div  className="column" >
						<figure style={productFigureStyle}>
							<img src={logoToolkit} alt='Toolkit Logo' style={iconLogoFigureStyle}/>
							<BtnSolid name='Toolkit'  type='button' cta={usePermissions('cid::web_access',false) ? () => handleToolkit() : () => sendStore()} style={blueButton} />
						</figure>
					</div>
					<div  className="column" >
						<figure style={productFigureStyle}>
							<img src={logoCaseManager} alt='Torch Logo' style={iconLogoFigureStyle}/>
							<BtnSolid name='Case Manager' type='button' cta={usePermissions('torch::web_access',false) ? () => handleCaseManager() : () => sendStore()} />
						</figure>
					</div>
					
				</div>
				</div>
			</nav>
		</>
	)
}

export default LandingPage
