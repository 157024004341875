import React, { useState } from 'react'

import config from '../../api/config'
// Components
import InputField from '../Elements/InputField'
import Hyperlink from '../Elements/Hyperlink'
import Button from '../Elements/Button'
import { mixpanelService } from '../../api/mixpanel-service'

// API
import { authenticationService } from '../../api'

function LoginForm(props) {
  const [errors, setErrors] = useState({})

  function handleSubmit(e) {
    e.preventDefault()

    let form_element = document.getElementById('signup-form')
    let form_data    = new FormData(form_element)
    let body         = {}

    form_data.forEach((value, key) => body[key] = value)
    authenticationService.login(body)
      .then((response) => {
        if(response.cognito_id){
          props.onChange("logged", {user_id: response.cognito_id})
        }
        else{
          let SESSION     = response['Session']
          let USERNAME    = response['ChallengeParameters']['USER_ID_FOR_SRP']
          let medium      = response['ChallengeParameters']['CODE_DELIVERY_DELIVERY_MEDIUM']
          let destination = response['ChallengeParameters']['CODE_DELIVERY_DESTINATION']
          let method      = response['ChallengeName']
          props.onChange("2fa", { SESSION, USERNAME, medium, destination, method })
        }
      })
      .catch(error => {
        setErrors({ ...error })
      })
  }

    return(
        <form id="signup-form" onSubmit={handleSubmit}>
            <InputField name="USERNAME" label="Email" type="email" error={errors.USERNAME} required={true}/>
            <InputField name="PASSWORD" id="password-1" label="Password" type="password" error={errors.PASSWORD || errors.non_field_errors} required={true}/>
            <p className="right mt-15"><Hyperlink text="Forgot password" className="inline" link="/recover-password" /> or <Hyperlink text="Verify email" className="inline" link="/verify" /></p>
            <Button text="Sign in" class="orange-btn my-30 full-width" type="submit"/>
        </form>
    )
}

export default LoginForm