import config from './config'
import handleResponse from './handleResponse'

export const planService = {
	details
}

async function details(plan_id) {
	const requestOptions = { method: 'GET', credentials: 'include' }

	return fetch(`${config.apiUrl}/subscription/plan/${plan_id}/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}
