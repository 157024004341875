// import { useEffect, useState } from 'react'

// Assets
// import TorchLogo from '../../assets/img/torch-logo.png'
// import TorchLogoWhite from '../../assets/img/torch-logo-white.png'
import EchoLogo from '../../assets/img/echo-logo@4x.png'
import Hyperlink from '../../components/Elements/Hyperlink'

// Components
import Title from '../../components/Elements/Title'
import LoginForm from '../../components/RegistrationForms/LoginForm'

function RegistCard ( props ) {
	// const [ Logo, setLogo ] = useState( '' )
	// useEffect( () => {
	// 	if ( document.getElementById( 'body-wrapper' ).classList.contains( 'light' ) ) {
	// 		setLogo( TorchLogo )
	// 	} else {
	// 		setLogo( TorchLogoWhite )
	// 	}
	// }, [] )

	return (
		<div className='login-content col-4 screen-center'>
			<img src={ EchoLogo } className='logo' alt='echo-logo' />
			<div className='regist-card'>
				<div className='center'>
					<Title text='Welcome back!' />
				</div>
				<LoginForm onChange={ props.onChange } />
				<p className='text is-dark center'>New user?
					<Hyperlink text=' Sign up' className='inline' link='/signup' />
				</p>
			</div>
		</div>
	)
}

export default RegistCard
