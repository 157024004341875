import React from 'react'
import Title from '../../components/Elements/Title'
import SignupForm from '../../components/RegistrationForms/SignupForm'
import Hyperlink from '../../components/Elements/Hyperlink'
import EchoLogo from '../../assets/img/echo-logo@4x.png'

const SignupCard = (props) => {
  const { onChange } = props

  return (
    <div>
    <img src={EchoLogo} className="logo" alt="echo-logo" />
    <div className='login-content col-8 center'>     
      <div className="regist-card">
        <div className="row">
          <div className="col-6 left">
            <Title text="Welcome!" />
            <h2 className="subheading is-light left">Please enter your data to register</h2>
          </div>
        </div>

        <SignupForm onChange={onChange} />

        <div className="text is-dark center">
          Already have an account?
          <Hyperlink
            text=" Sign in"
            className="inline"
            link="/login" />
        </div>
      </div>
    </div>
    </div>
  )
}

export default SignupCard
