import {BrowserRouter, Route, Switch} from 'react-router-dom'
import useFindUser from '../components/Hooks/useFindUser'
import {UserContext} from '../components/Hooks/UserContext'
import PrivateRoute from '../components/PrivateRoute'
import {CartProvider} from '../context/index.context'

import {
	CheckoutPage,
	EnterpriseForm,
	ExternalCheckoutPage,
	ExternalStorePage,
	LandingPage,
	Login,
	Logout,
	Profile,
	RecoverPassword,
	Security,
	Signup,
	StorePage,
	VerifyEmail,
} from '../pages/index.pages'

import Test from '../components/Test'

const CommonRouter = () => {
	const { currentUser, setCurrentUser, currentOrganizations, setCurrentOrganizations, isLoading } =
		useFindUser()

	return (
		<BrowserRouter>
			<UserContext.Provider
				value={{
					currentUser,
					setCurrentUser,
					currentOrganizations,
					setCurrentOrganizations,
					isLoading,
				}}
			>
				<CartProvider>
					<Switch>
						<Route path="/login" component={ Login }/>
						<PrivateRoute exact path="/enterprise_information" component={ EnterpriseForm }/>
						<Route exact path="external/enterprise_information" component={ EnterpriseForm }/>
						<PrivateRoute path="/store/:org_id" component={ StorePage }/>
						<PrivateRoute exact path="/store" component={ StorePage }/>
						<Route exact path="/external/store" component={ ExternalStorePage }/>
						<PrivateRoute exact path="/checkout/:org_id" component={ CheckoutPage }/>
						<PrivateRoute exact path="/checkout" component={ CheckoutPage }/>
						<Route exact path="/external/checkout" component={ ExternalCheckoutPage }/>
						<Route exact path="/logout" component={ Logout }/>
						<Route exact path="/signup" component={ Signup }/>
						<Route exact path="/verify" component={ VerifyEmail }/>
						<Route exact path="/recover-password" component={ RecoverPassword }/>
						<PrivateRoute exact path='/' component={LandingPage} />
						<PrivateRoute exact path='/home' component={LandingPage} />
						<PrivateRoute exact path='/profile/:user_id' component={Profile} />
						<PrivateRoute exact path='/security/:user_id' component={Security} />
						{/* <PrivateRoute exact path='/test' component={Test} /> */}
					</Switch>
				</CartProvider>
			</UserContext.Provider>
		</BrowserRouter>
	)
}
export default CommonRouter
