import {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {toast} from 'react-toastify'
import axios from 'axios'

import {mixpanelService} from '../../api/mixpanel-service'
import {CartContext} from '../../context/index.context'
import {CardProduct, CardSkeletonProduct, HeaderWrapper} from '../../components/organisms/index.organisms'
import config from '../../api/config'

import logoTorch from '../../assets/img/logo__torch.png'

const ExternalStorePage = () => {
	const history = useHistory()

	const { cartProducts, setCartProducts } = useContext( CartContext )

	const [ onLoading, setOnLoading ] = useState( true )
	const [ productList, setProductList ] = useState( [] )

	const getProducts = async () => {
		try {
			const { data } = await axios.get( `/p-subscription/plans/`, {
				headers: {
					Authorization: config.externalApiKey,
				},
			} )

			return data
		} catch ( { message } ) {
			toast.error( message )
		} finally {
			setTimeout( () => {
				setOnLoading( false )
			}, 500 )
		}
	}

	const hdlAddProduct = ( selectedId ) => {
		productList.filter( ( product ) => {
			if ( product.id === selectedId ) {
				setCartProducts( [ ...cartProducts, product ] )
				mixpanelService.AddToShoppingCart( product )
				toast.success(
					`The product ${ product.name } has been added to your cart.` )
			}
		} )
	}

	const hdlProductTypes = ( productType ) => {
		switch ( cartProducts.length ) {
			case 1:
				if ( productType.length === 2 ) {
					return true
				}
				if ( cartProducts[ 0 ].product_types.length === 2 ) {
					return true
				}
				if ( cartProducts[ 0 ].product_types.includes( productType[ 0 ] ) ) {
					return true
				}
				break
			case 2:
				return true
			default:
				return false
		}
	}

	useEffect( () => {
		setOnLoading( true )
		mixpanelService.EnterStore()
		getProducts().then( ( results ) => {
			setProductList( results )
		} )
	}, [] )

	return <>
		<HeaderWrapper isExternal={ true }/>
		<PlanListStyled>
			{ onLoading
				? ( <>
					<CardSkeletonProduct/>
					<CardSkeletonProduct/>
					<CardSkeletonProduct/>
					<CardSkeletonProduct/>
				</> )
				: ( productList?.length && productList.map(
					( { id, name, prices, description, plan_benefits, product_types } ) => {

						return <CardProduct
							name={ name }
							key={ `item-${ id }` }
							benefits={ plan_benefits }
							description={ description }
							cta={ () => hdlAddProduct( id ) }
							price={ prices?.length > 0 && prices[ 0 ] }
							isDisabled={ hdlProductTypes( product_types ) }
							logo={ logoTorch }
							btnName={ 'Add To Cart' }
						/>
					} ) ) }
			<CardProduct
				logo={ logoTorch }
				onLoad={ onLoading }
				name={ 'Enterprise' }
				isEnterprise={ true }
				btnName={ 'Request Information' }
				description={ 'The EAG Business Development Team will contact you to create a custom plan and pricing to meet your specific needs. Upon your request, you can receive one week trial access to a basic user license.' }
				cta={ () => history.push( '/enterprise_information' ) }
			/>
		</PlanListStyled>
	</>

}

const PlanListStyled = styled.section`
  display: grid;
  padding: 1rem;
  grid-gap: 1rem;
  overflow-y: auto;

  @media (min-width: 0) and (orientation: landscape) {
    grid-template-columns: repeat(2, calc(50% - .5rem));
  }

  @media (min-width: 800px) {
    grid-template-columns: repeat(3, calc(calc(100% / 3) - calc(2rem / 3)));
  }

  @media (min-width: 1280px ) {
    grid-template-columns: repeat(4, calc(calc(100% / 4) - calc(3rem / 4)));
    padding: 0 8rem 3rem;
  }
`

export default ExternalStorePage
