import React from 'react'
import styled from 'styled-components'

import FormFieldSet from '../molecules/Form/FieldSet'
import {FormInput} from '../atoms/index.atoms'

const BillingForm = ( { register, errors } ) => {
	return (
		<div>
			<Title>Billing Address</Title>
			<WrapperStyled>
				<FormFieldSet
					onErr={ errors?.firstName }
					errMsg={ errors?.firstName?.message }
					name={ 'First name' }
					style={ { gridColumnStart: 1, gridColumnEnd: 4 } }
				>
					<FormInput
						onErr={ errors?.firstName }
						customAttributes={ {
							...register( 'firstName',
								{
									required: {
										value: true,
										message: 'This field is required.',
									},
								} ),
						} }
						isRequired
					/>
				</FormFieldSet>

				<FormFieldSet
					onErr={ errors.lastName }
					errMsg={ errors.lastName?.message }
					name={ 'Last name' }
					style={ { gridColumnStart: 4, gridColumnEnd: 7 } }
				>
					<FormInput
						onErr={ errors.lastName }
						customAttributes={ {
							...register( 'lastName',
								{
									required: {
										value: true,
										message: 'This field is required.',
									},
								} ),
						} }
						isRequired
					/>
				</FormFieldSet>

				<FormFieldSet
					onErr={ errors?.email }
					errMsg={ errors?.email?.message }
					name={ 'Email' }
					style={ { gridColumnStart: 1, gridColumnEnd: 7 } }
				>
					<FormInput
						onErr={ errors?.email }
						customAttributes={ {
							...register( 'email',
								{
									required: {
										value: true,
										message: 'This field is required.',
									},
								} ),
						} }
						type="email"
						isRequired
					/>
				</FormFieldSet>

				<FormFieldSet
					onErr={ errors?.streetAddress }
					errMsg={ errors?.streetAddress?.message }
					name={ 'Street address' }
					style={ { gridColumnStart: 1, gridColumnEnd: 7 } }
				>
					<FormInput
						onErr={ errors?.streetAddress }
						customAttributes={ {
							...register( 'streetAddress',
								{
									required: {
										value: true,
										message: 'This field is required.',
									},
								} ),
						} }
						isRequired
					/>
				</FormFieldSet>

				<FormFieldSet
					onErr={ errors?.phone }
					errMsg={ errors?.phone?.message }
					name={ 'Phone' }
					style={ { gridColumnStart: 1, gridColumnEnd: 7 } }
				>
					<FormInput
						onErr={ errors?.phone }
						customAttributes={ {
							...register( 'phone',
								{
									required: {
										value: true,
										message: 'This field is required.',
									},
								} ),
						} }
						isRequired
					/>
				</FormFieldSet>

				<FormFieldSet
					onErr={ errors?.state }
					errMsg={ errors?.state?.message }
					name={ 'State' }
					style={ { gridColumnStart: 1, gridColumnEnd: 7 } }
				>
					<FormInput
						onErr={ errors?.state }
						customAttributes={ {
							...register( 'state',
								{
									required: {
										value: true,
										message: 'This field is required.',
									},
								} ),
						} }
						isRequired
					/>
				</FormFieldSet>

				<FormFieldSet
					onErr={ errors?.city }
					errMsg={ errors?.city?.message }
					name={ 'City' }
					style={ { gridColumnStart: 1, gridColumnEnd: 4 } }
				>
					<FormInput
						onErr={ errors?.city }
						customAttributes={ {
							...register( 'city',
								{
									required: {
										value: true,
										message: 'This field is required.',
									},
								} ),
						} }
						isRequired
					/>
				</FormFieldSet>

				<FormFieldSet
					onErr={ errors?.zip }
					errMsg={ errors?.zip?.message }
					name={ 'Zip code' }
					style={ { gridColumnStart: 4, gridColumnEnd: 7 } }
				>
					<FormInput
						onErr={ errors?.zip }
						customAttributes={ {
							...register( 'zip',
								{
									required: {
										value: true,
										message: 'This field is required.',
									},
								} ),
						} }
						isRequired
					/>
				</FormFieldSet>
			</WrapperStyled>
		</div>
	)
}

const WrapperStyled = styled.section`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(6, 1fr);
`

const Title = styled.h2`
  font-size: 16px;
  margin: 0 0 20px;
`

export default BillingForm
