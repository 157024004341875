import React from "react";
import RegisterStepForm from "./RegisterStepForm";
import MultiStep from "../MultiSteps";

const RegisterStep = ({ currentMethod, serverErrors, onSubmit }) => {
  return (
    <>
      <h1 className="title-2 is-dark line-margin-0">
        {currentMethod === "SMS"
          ? "Two Factor Authentication"
          : "Authenticator App Setup"}
      </h1>
      <MultiStep current={2} />
      <RegisterStepForm
        currentMethod={currentMethod}
        serverErrors={serverErrors}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default RegisterStep;
