import React from 'react'
import { Link } from 'react-router-dom'


const Hyperlink = props => {
  const { text, className, linkClass, icon, iconClass, link } = props
  return (
    <div className={className}>
      <Link
        className={`${linkClass} link`}
        to={link}>
        {(icon) && <i className={`${icon} ${iconClass}`}></i>}
        {text}
      </Link>
    </div>
  )
}

Hyperlink.defaultProps = {
  text: '',
  className: '',
  linkClass: '',
  icon: '',
  iconClass: '',
  link: '',
}

export default Hyperlink
