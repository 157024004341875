import { authenticationService } from './'
import config from './config'
import handleResponse from './handleResponse'
import { fixBody } from './helpers'

export const organizationService = {
	update,
	retrieve,
	listByUser,
	getOrCreate,
	attachPaymentMethods,
	paymentMethods
}

async function listByUser() {
	// await checkToken()
	const requestOptions = { method: 'GET', credentials: 'include' }
	return fetch(`${config.apiUrl}/organization/`, requestOptions)
		.then(handleResponse)
		.then((organizations) => {
			const organizationsObj = {}
			for (const org of organizations) {
				organizationsObj[org.id] = org
			}
			authenticationService.updateOrgMember(organizationsObj)
			return organizations
		})
}

async function update(body, org_id) {
	// await checkToken()
	body = fixBody(body)
	const requestOptions = { method: 'PATCH', credentials: 'include', body }
	return fetch(`${config.apiUrl}/organization/${org_id}/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function retrieve(org_id) {
	// await checkToken()
	const requestOptions = {
		method: 'GET',
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/organization/${org_id}/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function getOrCreate() {
	// await checkToken()
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/organization/get_or_create/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function attachPaymentMethods(org_id, body) {
	body = fixBody(body)
	const requestOptions = { method: 'POST', credentials: 'include', body: JSON.stringify(body), 'headers': {'Content-Type': 'application/json'}}

	return fetch(`${config.apiUrl}/organization/${org_id}/attach_payment_method/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}

async function paymentMethods(org_id) {
	const requestOptions = { method: 'GET', credentials: 'include' }

	return fetch(`${config.apiUrl}/organization/${org_id}/payment_methods/`, requestOptions)
		.then(handleResponse)
		.then((org) => {
			return org
		})
}