import React from "react"
import styled from "styled-components"

import visa from './icons/visa.svg'
import discover from './icons/discover.svg'
import maestro from './icons/maestro.svg'
import mastercard from './icons/mastercard.svg'


const CardIcons = () => {
  return (
    <Icons>
      <img src={visa} alt='Visa' />
      <img src={discover} alt='discover' />
      <img src={maestro} alt='maestro' />
      <img src={mastercard} alt='mastercard' />
    </Icons>
  );
}

const Icons = styled.div`
  display: flex;
  justify-content: flex-end;

  & img {
    margin-right: 5px;
    width: 43px;
    
    &:last-child {
      margin-right: 0;
    }
  }
`

export default CardIcons
