import React from 'react'

function Test(props) {
 /*  console.log('REACT_APP_API_URI', process.env.REACT_APP_API_URI)
  console.log('REACT_APP_WEBSOCKET_URL', process.env.REACT_APP_WEBSOCKET_URL)
  console.log('REACT_APP_MEDIA_URI', process.env.REACT_APP_MEDIA_URI)
  console.log('REACT_APP_HOME_URI', process.env.REACT_APP_HOME_URI)
  console.log('REACT_APP_CID_URI', process.env.REACT_APP_CID_URI)
  console.log('REACT_APP_TORCH_URI', process.env.REACT_APP_TORCH_URI)
  console.log('REACT_APP_STRIPE_CLIENT_SECRET', process.env.REACT_APP_STRIPE_CLIENT_SECRET)
  console.log('REACT_APP_ENV', process.env.REACT_APP_ENV) */

  return null
}

export default Test
