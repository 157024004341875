import benefitIcon from '../../../../assets/img/benefit_item.png'
import logo from '../../../../assets/img/logo__echo.png'
import { BtnSolid } from '../../../atoms/index.atoms'

const CardSkeletonProduct = () => (
	<article
		style={{
			width: '100%',
			rowGap: '1rem',
			display: 'flex',
			padding: '1.5rem',
			overflow: 'hidden',
			minHeight: '28rem',
			position: 'relative',
			alignItems: 'center',
			borderRadius: '.5rem',
			flexDirection: 'column',
			backgroundColor: 'white',
			boxShadow: '0 0 10px 4px #eee',
		}}
		className={'skeleton-animation'}
	>
		<span
			style={{
				minWidth: '6rem',
				lineHeight: '1.2',
				fontWeight: 'bold',
				borderWidth: '1px',
				textAlign: 'center',
				width: 'fit-content',
				borderStyle: 'solid',
				padding: '.8rem 1rem',
				borderColor: '#C4C4C4',
				borderRadius: '1.5rem',
				textTransform: 'capitalize',
			}}
		>
			<div
				style={{
					width: '100%',
					height: '.8rem',
					borderRadius: '.2rem',
					backgroundColor: '#C4C4C4',
				}}
			/>
		</span>
		<figure
			style={{
				margin: 0,
				padding: 0,
				width: '100%',
				textAlign: 'center',
				paddingBottom: '1rem',
				borderBottom: '1px solid #ececec',
			}}
		>
			<img
				src={logo}
				alt={'Logo Echo Analytics'}
				style={{
					height: '4rem',
					filter: 'grayscale(100%) brightness(20%) opacity(30%)',
				}}
			/>
		</figure>
		<ul
			style={{
				margin: 0,
				padding: 0,
				width: '100%',
				rowGap: '.2rem',
				listStyleType: 'none',
				display: 'inline-grid',
			}}
		>
			<li style={{ width: '100%', display: 'flex', alignItems: 'baseline' }}>
				<img
					src={benefitIcon}
					alt={'Icon'}
					style={{
						marginRight: '.25rem',
						filter: 'grayscale(100%) brightness(20%) opacity(30%)',
					}}
				/>
				<div
					style={{
						width: '100%',
						height: '.8rem',
						borderRadius: '0.2rem',
						backgroundColor: '#C4C4C4',
					}}
				/>
			</li>
			<li style={{ width: '100%', display: 'flex', alignItems: 'baseline' }}>
				<img
					src={benefitIcon}
					alt={'Icon'}
					style={{
						marginRight: '.25rem',
						filter: 'grayscale(100%) brightness(20%) opacity(30%)',
					}}
				/>
				<div
					style={{
						width: '100%',
						height: '.8rem',
						borderRadius: '0.2rem',
						backgroundColor: '#C4C4C4',
					}}
				/>
			</li>
			<li style={{ width: '100%', display: 'flex', alignItems: 'baseline' }}>
				<img
					src={benefitIcon}
					alt={'Icon'}
					style={{
						marginRight: '.25rem',
						filter: 'grayscale(100%) brightness(20%) opacity(30%)',
					}}
				/>
				<div
					style={{
						width: '100%',
						height: '.8rem',
						borderRadius: '0.2rem',
						backgroundColor: '#C4C4C4',
					}}
				/>
			</li>
			<li style={{ width: '100%', display: 'flex', alignItems: 'baseline' }}>
				<img
					src={benefitIcon}
					alt={'Icon'}
					style={{
						marginRight: '.25rem',
						filter: 'grayscale(100%) brightness(20%) opacity(30%)',
					}}
				/>
				<div
					style={{
						width: '100%',
						height: '.8rem',
						borderRadius: '0.2rem',
						backgroundColor: '#C4C4C4',
					}}
				/>
			</li>
		</ul>
		<ul
			style={{
				margin: 0,
				padding: 0,
				width: '100%',
				rowGap: '.2rem',
				listStyleType: 'none',
				display: 'inline-grid',
				justifyItems: 'center',
			}}
		>
			<div
				style={{
					width: '80%',
					height: '1.2rem',
					borderRadius: '0.2rem',
					backgroundColor: '#C4C4C4',
				}}
			/>
			<div
				style={{
					width: '90%',
					height: '1.2rem',
					borderRadius: '0.2rem',
					backgroundColor: '#C4C4C4',
				}}
			/>
			<div
				style={{
					width: '70%',
					height: '1.2rem',
					borderRadius: '0.2rem',
					backgroundColor: '#C4C4C4',
				}}
			/>
			<div
				style={{
					width: '50%',
					height: '1.2rem',
					borderRadius: '0.2rem',
					backgroundColor: '#C4C4C4',
				}}
			/>
			<div
				style={{
					width: '20%',
					height: '1.2rem',
					borderRadius: '0.2rem',
					backgroundColor: '#C4C4C4',
				}}
			/>
		</ul>
		<div
			style={{
				width: '100%',
				rowGap: '.2rem',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<div
				style={{ width: '30%', height: '.8rem', borderRadius: '.2rem', backgroundColor: '#C4C4C4' }}
			/>
			<div
				style={{
					width: '60%',
					height: '1.2rem',
					borderRadius: '0.2rem',
					backgroundColor: '#C4C4C4',
				}}
			/>
		</div>
		<BtnSolid style={{ width: '100%' }} onLoad={true} isDisabled={true} />
	</article>
)

export default CardSkeletonProduct
