import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'


// API
import { authenticationService, userService } from '../api'

function Logout() {
  const [stage, setStage] = useState("logout")

  useState(()=>{
    authenticationService.logout()
    .then(res=>{
      setStage('redirect')
    })
  }, [])
  
  if(stage === 'redirect') {
    return <Redirect to='/login'></Redirect>
  }
  
  return <React.Fragment></React.Fragment>
}

export default Logout
