import { useState, useEffect } from "react";
import { userService, authenticationService } from "../../api";
import Modal from "../Elements/Modal";
import SetupStep from "./Steps/SetupStep";
import RegisterStep from "./Steps/RegisterStep";
import VerifiedStep from "./Steps/VerifiedStep";

const SecuritySetup = ({
  isOpen,
  handleClose,
  currentUser,
  currentMethod,
  updateSecurity,
}) => {
  const [stage, setStage] = useState("first");
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const changeData = (new_data) => {
    setData({ ...data, ...new_data });
  };

  const handleCloseModal = () => {
    handleClose();
    setStage("first");
    setData({});
    setErrors({});
  };

  const handleSubmit = ({ code }) => {
    if (currentMethod === "SMS") {
      let body = { AttributeName: "phone_number", Code: code };
      userService
        .verify_attribute(body)
        .then(() => {
          let mfa_settings = {
            SMSMfaSettings: {
              Enabled: true,
              PreferredMfa: true,
            },
            SoftwareTokenMfaSettings: {
              Enabled: false,
              PreferredMfa: false,
            },
          };
          authenticationService.MFA_settings(mfa_settings).then(() => {
            updateSecurity({ ...currentUser, mfa_settings });
            setStage("third");
          });
        })
        .catch((error) => {
          console.error("It was not possible to verify phone", error);
          setErrors(error);
        });
    }

    if (currentMethod === "APP") {
      let body = { UserCode: code };
      authenticationService
        .verifyQrCode(body)
        .then(() => {
          let mfa_settings = {
            SMSMfaSettings: {
              Enabled: false,
              PreferredMfa: false,
            },
            SoftwareTokenMfaSettings: {
              Enabled: true,
              PreferredMfa: true,
            },
          };
          authenticationService.MFA_settings(mfa_settings).then(() => {
            updateSecurity({ ...currentUser, mfa_settings });
            setStage("third");
          });
        })
        .catch((error) => {
          console.error("It was not possible to verify QR code", error);
          setErrors(error);
        });
    }
  };

  useEffect(() => {
    if (stage === "completed") {
      handleCloseModal();
    }
  }, [stage]);

  return (
    <Modal isOpen={isOpen} fullScreen={true} handleClose={handleCloseModal}>
      {stage === "first" && (
        <SetupStep
          onNext={() => setStage("second")}
          currentMethod={currentMethod}
          currentUser={currentUser}
        />
      )}
      {stage === "second" && (
        <RegisterStep
          currentMethod={currentMethod}
          serverErrors={errors}
          onSubmit={handleSubmit}
        />
      )}
      {stage === "third" && (
        <VerifiedStep
          onNext={() => setStage("completed")}
          currentMethod={currentMethod}
        />
      )}
    </Modal>
  );
};

export default SecuritySetup;
