import React, {useState} from 'react'
import InputField from '../Elements/InputField'
import RadioButton from '../Elements/RadioButton'
import Checkbox from '../Elements/Checkbox'
import Button from '../Elements/Button'
import { registrationService } from '../../api'
import { mixpanelService } from '../../api/mixpanel-service'
import ReactFlagsSelect from 'react-flags-select'
import Tooltip from '../Elements/Tooltip'

const SignupForm = (props) => {
	const [errors, setErrors] = useState({})
	const [calling_code, setCallingCode] = useState("")
 	const country_calling_codes = { US: { primary: '+1',},
									PH: { primary: '+63'},
									BA: { primary:  '+387'}
								}
	const [selected, setSelected] = useState("");

    function changeCountryCallingCode(value) {
		setCallingCode(country_calling_codes[value]['primary'])
		setSelected(value)
	}
	function handleSubmit(e) {
		e.preventDefault()

		const Pass1 = e.target.password.value
		const Pass2 = e.target.password_confirmation.value

		if (Pass1 === Pass2) {

			var form_element = document.getElementById('signup-form')
			var form_data = new FormData(form_element)

		    var errors_ = {}
			var body = {}
			form_data.forEach((value, key) => {

                body['username'] = body['email']
				if (key.includes('phone')){

					if (!/^[0-9]+$/.test(value)) {
						errors_[key] = 'This field can only contain numbers.'
					}
					else{

						value = calling_code + value
						body[key] = value
						//console.log(value)
					}
				}
				if (key.includes('dob')){

					var my_dob = new Date(value)
					my_dob = new Date(my_dob.getUTCFullYear(), my_dob.getUTCMonth(), my_dob.getUTCDate());

					var today = new Date()
					var max_dob = new Date(today.getUTCFullYear() - '18', today.getUTCMonth(), today.getUTCDate())

					if (max_dob.getTime() < my_dob.getTime()){
						errors_[key] = 'Age has to be 18 or older'
					}else{
						body[key] = value
					}

				}
				body[key] = value
			})
		 	if (Object.keys(errors_).length > 0) {
				setErrors(errors_)
				return
			}
			registrationService.signup(body)
				.then((user) => {
					
					props.onChange("verification", { "Username": body["username"], "user_id": user.user_id })

					mixpanelService.SignUp(body)
				})
				.catch(error => {
					setErrors({ ...error })
				})
		} else {
			setErrors({ "password_confirmation": "Passwords do not match." })
		}
	}

	const private_text = 
	<div className="left" style={{padding: '0px 10px'}}>
		<p className="text line-margin-0 is-ligth">A private profile displays only limited information. </p>
		<p className="text line-margin-0 is-ligth">Any member of your organization can see your public information, including your </p>
		<p className="text line-margin-0 is-ligth">name, profile picture, alias, email, social network links, and role.</p>
		<p className="text line-margin-0 is-ligth">Admins and super admins also can see your phone and occupation.</p>
		<p className="text line-margin-0 is-ligth">Anyone outside your organization only can see your name, profile picture, and alias.</p>
		<p className="text line-margin-0 is-ligth"></p>
	</div>

	return (
		<form id="signup-form" onSubmit={handleSubmit}>
			<div className="row mt-15">
				<InputField name="first_name" label="First name" type="text" class="col-6 left" required={true} error={errors.first_name} />
				<InputField name="middle_name" label="Middle name" type="text" class="col-6 left" error={errors.middle_name} />
			</div>
			<div className="row mt-15">
				<InputField name="last_name" label="Last name" type="text" class="col-6 left" required={true} error={errors.last_name} />
				<InputField name="alias" label="Alias" type="text" class="col-6 left" error={errors.alias} />
			</div>
			<div className="row mt-15">
					<InputField name="dob" id="dob" label="Date of birth" type="date" class="col-6 left" required={true} error={errors.dob} />
     		</div>
			<div className="row mt-5">
				<span className="col-6 text-signup is-dark left ">Note: You must be 18+ years of age.</span>
			</div>
			<div className="row mt-15">
				<InputField name="email" label="Email" type="email" class="col-6 left" required={true} error={errors.email} />
			        <div class={'flexer'}>
						<ReactFlagsSelect
							selected={selected}
							countries={['US', 'PH', 'BA']}
							customLabels={country_calling_codes}
							onSelect={changeCountryCallingCode}
						    className={'flexer col-5 mt-20'}
							
						/>
					<InputField
						type='tel'
						class="col-9 left"
						name='phone_number'
						label='Phone'
						required={true}
						error={errors.phone_number}
					/>
				</div>
			</div>
			<div style={{display: 'flex', justifyContent:'flex-end', margin: 0, padding: 0}}>
				<Checkbox name="accept_sms" label="I consent to receive SMS for 2FA purposes" required={true}>
				</Checkbox>
			</div>
			<div className="row mt-15">
					<InputField id="password-1" name="password" label="Password" type="password" class="col-6 left" required={true} error={errors.password} />
					<InputField id="password-2" name="password_confirmation" label="Confirm Password" type="password" class="col-6 left" required={true} error={errors.password_confirmation} />
			</div>
			<div className="row mt-5">
				<span className="col-6 text-signup is-dark left"> Note: Passwords must be 8 characters minimum (upper case and lower case letters).</span>
			</div>
			<div className="row mt-30">
				<span className="col-2 heading is-dark left">Type of profile: </span>
				<Tooltip content={private_text} direction="bottom-x" class="col-4">
					<RadioButton label="Private" class="col-4" name="private" required={true} value="true" />
				</Tooltip>
				<RadioButton label="Public" class="col-2" name="private" required={true} value="false" />
			</div>
			<div className="row mt-5">
				<span className="col-6 text-signup is-dark left">Note: Public profiles indicate your profile is able to be discovered by other users, Private maintains your profile is hidden and unreadable by other users.</span>
			</div>
			<div className="row mt-15 left">
				<Checkbox name="accept_data_usage" label="I accept" required={true} error={errors.terms || errors.non_field_errors}>
					<a href="https://692348871699-echo-files.s3.amazonaws.com/media/torch/resources/Torch_Privacy_Policy_20210709.docx" className="text is-orange inline">Data usage statement/agreement.</a>
				</Checkbox>
			</div>
			<div className="center">
				<Button text="Submit" class="button orange-btn my-30 half-width" type="submit" />
			</div>
		</form>
	)
}

export default SignupForm
