import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Outline = ({ name, cta, type, className, isDisabled, onLoad }) => (
	<StyledButton
		title={name}
		onClick={cta}
		className={`${className}`}
		type={type}
		disabled={isDisabled}
	>
		{onLoad ? 'Loading' : name}
	</StyledButton>
)

Outline.propTypes = {
	name: PropTypes.string,
	cta: PropTypes.func.isRequired,
	type: PropTypes.oneOf(['submit', 'button', 'reset']).isRequired,
	className: PropTypes.string,
	isDisabled: PropTypes.bool,
	onLoad: PropTypes.bool,
}

Outline.defaultProps = {
	type: 'button',
	isDisabled: false,
	onLoad: false,
}

const StyledButton = styled.button`
	background-color: transparent;
	border: 1px solid #f69221;
	border-radius: 0.25rem;
	min-width: 9rem;
	padding: 0.5rem 1.25rem;
	cursor: pointer;
	font-size: 1rem;
	color: #f69221;
	transition: all 500ms ease-in-out;
	position: relative;

	:hover {
		color: #d47810;
		border-color: #d47810;
	}

	::before {
		display: inline-block;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		position: absolute;
		left: 0.5rem;
		content: '\f060';
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		color: #f69221;
	}
`

export default Outline
