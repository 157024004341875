import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {toast} from 'react-toastify'
import axios from 'axios'
import logoTorch from '../assets/img/logo__torch.png'
import {CartContext} from '../context/index.context'
import {CardProduct, CardSkeletonProduct, HeaderWrapper} from '../components/organisms/index.organisms'
import {mixpanelService} from '../api/mixpanel-service'
import useOrgId from '../components/Hooks/useOrgId'
import {subscriptionService} from '../api'

const StorePage = () => {
	const history = useHistory()

	const { cartProducts, setCartProducts } = useContext( CartContext )

	const [ paginator, setPaginator ] = useState( {} )
	const [ onLoading, setOnLoading ] = useState( true )
	const [ productList, setProductList ] = useState( [] )
	const [ currentSubscriptions, setCurrentSubscriptions ] = useState( null )

	const orgId = useOrgId()

	useEffect( () => {
		if ( orgId ) {
			subscriptionService.list( orgId )
				.then( response => {
					if ( response ) {
						setCurrentSubscriptions( response )
					}
				} )
				.catch( error => {
					console.error( 'It was not possible to retrieve organization subscriptions', error )
				} )
		}
	}, [ orgId ] )

	const getProducts = async () => {
		try {
			const { data } = await axios( `/subscription/plan/` )

			return data
		} catch ( { message } ) {
			toast.error( message )
		} finally {
			setTimeout( () => {
				setOnLoading( false )
			}, 500 )
		}
	}

	const hdlAddProduct = ( selectedId ) => {
		productList.filter( ( product ) => {
			if ( product.id === selectedId ) {
				setCartProducts( [ ...cartProducts, product ] )
				mixpanelService.AddToShoppingCart( product )
				toast.success( `The product ${ product.name } has been added to your cart.` )
			}
		} )
	}

	const handleCartChange = () => {
		// check cart contents
		return cartProducts.length > 0 ? true : false;
	}

	useEffect( () => {
		setOnLoading( true )
		mixpanelService.EnterStore()
		getProducts().then( ( { results, count, next, previous } ) => {
			setProductList( results )
			setPaginator( {
				count: count,
				next: next,
				previous: previous,
			} )
		} )
	}, [] )

	return (
		<>
			<HeaderWrapper/>
			<BackButtonStyled onClick={ () => history.push( '/' ) } type={ 'button' }>
				<FontAwesomeIcon icon={ faAngleLeft }/>
				Back to Home
			</BackButtonStyled>
			<PlanListStyled>
				{ onLoading
					? (
						<>
							<CardSkeletonProduct/>
							<CardSkeletonProduct/>
							<CardSkeletonProduct/>
						</>
					)
					: (
						productList?.length &&
						productList.map( ( { id, name, prices, description, plan_benefits, product_types } ) => {
							let active = currentSubscriptions?.[ 0 ]?.plans.map( plan => plan.id ).includes( id )
							let hasActivePlan = currentSubscriptions?.[ 0 ]
							let activePrice = currentSubscriptions?.[ 0 ]?.prices?.[ 0 ]?.price
							let downgrade = false
							if ( activePrice && prices?.length > 0 && prices[ 0 ].price < activePrice ) {
								downgrade = true
							}
							// 
							return <CardProduct
								name={ name }
								key={ `item-${ id }` }
								benefits={ plan_benefits }
								description={ description }
								cta={ () => hdlAddProduct( id ) }
								isDisabled={ handleCartChange() } // force update PLAT-601
								price={ prices?.length > 0 && prices[ 0 ] }
								logo={ logoTorch }
								btnName={ active
									? 'Current Plan'
									: downgrade
										? 'Downgrade (Cancel Plan First)'
										: hasActivePlan
											? 'Update Plan'
											: 'Add To Cart' }
							/>
						} )
					) }
				<CardProduct
					logo={ logoTorch }
					onLoad={ onLoading }
					name={ 'Enterprise' }
					isEnterprise={ true }
					btnName={ 'Request Information' }
					description={
						'The EAG Business Development Team will contact you to create a custom plan and pricing to meet your specific needs. Upon your request, you can receive one week trial access to a basic user license.'
					}
					cta={ () => history.push( '/enterprise_information' ) }
				/>
			</PlanListStyled>
		</>
	)
}

const PlanListStyled = styled.section`
  display: grid;
  padding: 2rem;
  grid-gap: 2rem;
  overflow-y: auto;

  @media (min-width: 0) and (orientation: landscape) {
    grid-template-columns: repeat(2, calc(50% - .5rem));
	display: flex;
  }

  @media (min-width: 800px) {
    grid-template-columns: repeat(3, calc(calc(100% / 3) - calc(2rem / 3)));
	display: flex;
  }

  @media (min-width: 1280px ) {
    grid-template-columns: repeat(4, calc(calc(100% / 4) - calc(3rem / 4)));
    padding: 0 8rem 3rem;
	display: flex;
  }
`

const BackButtonStyled = styled.button`
  cursor: pointer;
  margin-top: 1rem;
  margin-left: 8rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  color: var(--orange-btn);
  border: 1px solid var(--border);
  border-radius: 0 1.5rem 1.5rem 0;
  background-color: var(--card-bg);

  & svg {
    margin-right: 0.25rem;

    & path {
      fill: var(--orange-btn) !important;
    }
  }
`

export default StorePage
