import {faAngleDown, faCartShopping} from '@fortawesome/free-solid-svg-icons'
import {Link, useHistory, useLocation} from 'react-router-dom'
import React, {useContext, useEffect, useState} from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar'

import {authenticationService} from '../../../api'
import {CartContext} from '../../../context/index.context'
import {BtnIconSingle, IconSingle, Logo} from '../../atoms/index.atoms'
import {UserContext} from '../../Hooks/UserContext'
import {HeaderUserMenu} from '../../molecules/index.molecules'
import CartWrapper from '../Cart/Wrapper'

import useOrgId from '../../Hooks/useOrgId'

import echoLogo from '../../../assets/img/logo__echo.png'

const HeaderWrapper = ( { isExternal } ) => {
	const { currentUser } = useContext( UserContext )
	const { cartProducts } = useContext( CartContext )

	const history = useHistory()
	const { pathname } = useLocation()

	const [ pricesId, setPricesId ] = useState( [] )
	const [ isOpen, setIsOpen ] = useState( false )
	const [ cartIsOpen, setCartIsOpen ] = useState( false )

	const orgId = useOrgId()

	const hdlLogout = () => {
		authenticationService.logout().then( () => {
			window.location.assign( `/login` )
		} ).catch( ( error ) => {
			console.error( 'Logout failed', error )
		} )
	}

	const hdlPricesID = () => {
		setPricesId( [] )

		cartProducts.map( ( { price_id } ) => {
			setPricesId( [ ...pricesId, price_id ] )
		} )
	}


	useEffect( () => {
		hdlPricesID()
	}, [ cartProducts ] )

	var name = !isExternal ? currentUser.public_name : null
	var initials = name ? Array.prototype.map.call(name.split(' '), function(x){ return x.substring(0,1).toUpperCase();}).join('') : null


	return ( <StyledHeader>
		<figure style={ { height: '2.5rem', margin: 0 } }>
			<Logo name={ 'Echo' } img={ echoLogo } style={ { height: '100%' } }/>
		</figure>
		<SectionStyled>
			{ pathname.startsWith( '/store' ) || isExternal
				? (
					<AsideStyled>
						{ cartProducts?.length > 0
							? (
								<span className="fa-stack fa-1x"
											data-count={ cartProducts?.length }>
								<i
									className="fa fa-shopping-cart fa-stack-1x cart cursor"
									onClick={ () => setCartIsOpen( !cartIsOpen ) }
								/>
							</span>
							)
							: (
								<BtnIconSingle icon={ faCartShopping }
															 cta={ () => setCartIsOpen(
																 !cartIsOpen ) }/>
							) }

						<CartWrapper
							showPromoCode={ false }
							cta={ () => history.push( isExternal
								? '/external/checkout'
								: `/checkout/${ orgId ?? '' }` ) }
							isOpen={ cartIsOpen }/>
					</AsideStyled>
				)
				: (
					<AsideStyled>
						<Link
							to={ '/store' }
							style={ {
								display: 'flex',
								color: '#FFFFFF',
								columnGap: '.5rem',
								width: 'max-content',
								alignItems: 'center',
								borderRadius: '.4rem',
								padding: '.5rem .8rem',
								backgroundColor: 'var(--link)',
							} }
						>
							<IconSingle icon={ faCartShopping }
													className={ 'text-white' }/> Go to store
						</Link>
					</AsideStyled>
				) }
			{ !isExternal && <StyledFigure>
				<div className='ml-20'>{currentUser?.profile?.profile_pic ? (<img
					 className='MenuProfileImage'
					 src={currentUser?.profile?.profile_pic}
					 />) : (<div className='MenuProfileImage'>{initials}</div>)}
                    </div>
				<StyledFigcaption>{ currentUser?.public_name.toLowerCase() }</StyledFigcaption>
				<IconSingle
					icon={ faAngleDown }
					cta={ () => {
						setIsOpen( !isOpen )
					} }
					style={ { cursor: 'pointer' } }
				/>
				<HeaderUserMenu isOpen={ isOpen } id={ currentUser.cognito_id }
												hdlLogout={ hdlLogout }/>
			</StyledFigure> }
		</SectionStyled>
	</StyledHeader> )
}

const AsideStyled = styled.aside`
  position: relative;
`

const SectionStyled = styled.section`
  display: flex;
  align-items: center;
  grid-column-gap: 1rem;
`

const StyledFigcaption = styled.figcaption`
  flex-grow: 2;
  max-width: 10rem;
  width: 100%;
  padding: 0 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
`

const StyledFigure = styled.figure`
  margin: 0;
  width: 100%;
  align-items: center;
  display: inline-grid;
  grid-auto-flow: column;
`

const StyledHeader = styled.header`
  top: 0;
  width: 100%;
  display: flex;
  position: sticky;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem 1rem;
  background-color: #f4f6f9;
  justify-content: space-between;

  @media (min-width: 1280px) {
    padding: 0.5rem 8rem;
  }
`

export default HeaderWrapper
