import config from './config'
import handleResponse from './handleResponse'
import {mixpanelService} from './mixpanel-service'

export const authenticationService = {
	login,
	twoFA,
	logout,
	refreshToken,
	updateBasicInfo,
	updateOrgMember,
	getQrCode,
	verifyQrCode,
	MFA_settings,
}

function updateBasicInfo(value) {}

function updateOrgMember(response) {}

function login(body) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/auth/initiate_auth/`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			mixpanelService.LoginStep1(body)
			return response
		})
}

function twoFA(body) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/auth/respond_to_auth_challenge/`, requestOptions)
		.then(handleResponse)
		.then((tokens) => {
			return tokens
		})
}

async function refreshToken() {
	const requestOptions = {
		method: 'GET',
		credentials: 'include',
	}

	return await fetch(`${config.apiUrl}/auth/refresh_token/`, requestOptions)
		.then((response) => {
			return handleResponse(response)
		})
		.then((user) => {
			return user
		})
		.catch((error) => {
			console.log('failing refreshing token', error)
			logout()
		})
}

async function logout() {
	await fetch(`${config.apiUrl}/auth/logout/`).then(() => {
		mixpanelService.Logout()
		console.log('Logging out')
	})
}

async function getQrCode() {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
	}
	return await fetch(`${config.apiUrl}/auth/associate_software_token/`, requestOptions)
	.then(handleResponse)
	.then((resp) => {
		return resp
	})
}

async function verifyQrCode(body) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
		credentials: 'include',
	}

	return await fetch(`${config.apiUrl}/auth/verify_software_token/`, requestOptions)
		.then(handleResponse)
		.then((tokens) => {
			return tokens
		})
}
async function MFA_settings(body) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
		credentials: 'include',
	}

	return await fetch(`${config.apiUrl}/auth/set_user_mfa_preference/`, requestOptions)
		.then(handleResponse)
		.then((tokens) => {
			return tokens
		})
}
