import config from './config'
import handleResponse from './handleResponse'

export const registrationService = {
	signup,
	confirm_signup,
	resend_code,
	forgot_password,
	confirm_forgot_password,
}

async function signup(body) {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/auth/sign_up/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function confirm_signup(body) {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/auth/confirm_signup/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function resend_code(body) {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/auth/resend_confirmation_code/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function forgot_password(body) {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/auth/forgot_password/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function confirm_forgot_password(body) {
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/auth/forgot_password_confirmation/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}
