import { useState, useEffect } from "react";
import { userService, authenticationService } from "../../../api";
import InputField from "../../Elements/InputField";
import QRCode from "react-qr-code";
import Button from "../../Elements/Button";

const SetupStepForm = ({ onNext, currentMethod, currentUser }) => {
  const [codeQR, setCodeQR] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (currentMethod === "APP") {
      authenticationService
        .getQrCode()
        .then((resp) => {
          setCodeQR(resp.SecretCode);
        })
        .catch((error) => {
          console.log("Unable to get QR code");
        });
    }
  }, [currentMethod]);

  const handleContinue = () => {
    if (currentMethod === "SMS") {
      handleUpdatePhone();
    }
    if (currentMethod === "APP") {
      onNext();
    }
  };

  const handleUpdatePhone = () => {
    let phone = document.getElementsByName("phone_number")[0].value;
    if (phone?.length) {
      if (phone[0] === "+") {
        userService
          .update({ phone_number: phone }, currentUser.cognito_id)
          .then(() => {
            onNext();
          })
          .catch((error) => {
            setErrors((prevState) => {
              return { ...prevState, phone_number: error };
            });
          });
      } else {
        setErrors((prevState) => {
          return {
            ...prevState,
            non_field_errors:
              "Phone number must include international code area, e.g. +15555555",
          };
        });
      }
    } else {
      setErrors((prevState) => {
        return { ...prevState, non_field_errors: "This field is required" };
      });
    }
  };

  return (
    <>
      <section
        className="center"
        style={{
          height: "70%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <p className="title-2 is-dark is-bold">
            {currentMethod === "SMS" ? "Confirm Phone Number" : "Register"}
          </p>
          {currentMethod === "APP" && (
            <>
              <p className="text is-light">
                Open the authenticator app and scan the following QR Code
              </p>
              <QRCode
                value={`otpauth://totp/Torch (${currentUser.email})?secret=${codeQR}`}
                size={192}
              />
              <p className="text is-light mb-30">
                Or enter the following code manually{" "}
              </p>
              <p className="text is-light is-bold">{codeQR} </p>{" "}
            </>
          )}
          {currentMethod === "SMS" && (
            <>
              <p className="text is-light mb-30">
                We will send an SMS message to following number:
              </p>
              <InputField
                class="full-width line-margin-xs left"
                type="text"
                label={"Phone"}
                value={currentUser.phone_number}
                error={errors.phone_number || errors.non_field_errors}
                name="phone_number"
                required={true}
              />
            </>
          )}
          <Button
            text={currentMethod === "SMS" ? "Send Code" : "Continue"}
            class="mt-30 orange-btn half-width subheading-light"
            type="button"
            onClick={handleContinue}
          />
        </div>
      </section>
    </>
  );
};

export default SetupStepForm;
