import config from './config'
import handleResponse from './handleResponse'
import { checkToken, fixBody } from './helpers'

export const profileService = {
	update,
	updatePic,
	retrieve,
	create_info,
	delete_info,
	add_review,
}

async function update(body) {
	await checkToken()

	body = fixBody(body)

	const user_id = '' // authenticationService.currentUserValue.user_id

	const requestOptions = { method: 'PUT', credentials: 'include', body: body }

	return fetch(`${config.apiUrl}/profiles/${user_id}/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function updatePic(body, user_id) {
  const bodyFixed = fixBody(body)
  const requestOptions = { method: 'PUT', credentials: 'include', body: bodyFixed }

  return fetch(`${config.apiUrl}/profiles/${user_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function retrieve(user_id) {
	// const STATUS_OPTIONS = {
	//     ADMIN: 'Admin',
	//     CASE_MANAGER: 'Case manager',
	//     ANALYST: 'Analyst',
	//     UNASSIGNED: 'Unverified'
	// }

	// await checkToken()
	const requestOptions = { method: 'GET', credentials: 'include' }

	return fetch(`${config.apiUrl}/users/${user_id}/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function create_info(body, info_type) {
	// await checkToken()

	body = fixBody(body)

	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	}

	return fetch(`${config.apiUrl}/${info_type}/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function delete_info(info_id, info_type) {
	// await checkToken()

	const requestOptions = { method: 'DELETE', credentials: 'include' }

	return fetch(`${config.apiUrl}/${info_type}/${info_id}/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}

async function add_review(body) {
	// await checkToken()

	body = fixBody(body)

	const requestOptions = { method: 'POST', credentials: 'include', body: body }

	return fetch(`${config.apiUrl}/review/`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			return user
		})
}
