import SetupStepForm from "./SetupStepForm";
import MultiStep from "../MultiSteps";

const SetupStep = ({ onNext, currentMethod, currentUser }) => {
  return (
    <>
      <h1 className="title-2 is-dark line-margin-0">
        {currentMethod === "SMS"
          ? "Two Factor Authentication"
          : "Authenticator App Setup"}
      </h1>
      <MultiStep current={1} />
      <SetupStepForm
        onNext={onNext}
        currentMethod={currentMethod}
        currentUser={currentUser}
      />
    </>
  );
};

export default SetupStep;
