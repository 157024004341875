import { checkPropTypes } from 'prop-types'
import React , {useState}from 'react'
import Button from '../components/Elements/Button'
import Modal from '../components/Elements/Modal'


function CheckoutTermsOfServiceContent(props) {

    const [isModalOpen, setIsModalOpen] = useState(false)

    function handleAccept(){
        props.accepted = 'True'
        props.isDisabledBtn = !props.isDisabledBtn
        // var user_body = {
        //     accept_terms_of_service: true
        // }

    
        
    }

    return (
        <React.Fragment>
            <p className="text is-light">You authorize Echo Analytics Group to charge your account for the amount listed above.</p>
            <p className="text is-light">Your subscription renews automatically each year until you cancel.</p>
            <p className="text is-light">By clicking the button below, you understand and agree to these terms and Echo Analytics Group’s <a className='is-blue' target="_blank" href="https://echoanalyticsgroup.com/privacy/">privacy policy</a> and terms and conditions. </p>
            <br/><hr></hr><br/>
            <div className="flexer">
                <Button text="I read and accept terms of service" class={`orange-btn mt-15 full-width`} onClick={handleAccept}/>
                {/* {errors_} */}
                <Button text="I do not agree with terms of service" class={`white-btn mt-15  full-width`} onClick={()=>{setIsModalOpen(true)}}/>
            </div>
            <Modal isOpen={isModalOpen} handleClose={()=>{setIsModalOpen(false)}}>
                <div className="center">
                    <p className="text is-dark mx-30">You need to accept the terms of service in order to be able to use the Echo platform.</p>
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default CheckoutTermsOfServiceContent