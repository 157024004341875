import React, {useContext, useState} from 'react'

// Hooks
import {UserContext} from '../Hooks/UserContext'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'
import {mixpanelService} from '../../api/mixpanel-service'

// API
import {authenticationService, organizationService} from '../../api'

function TwoFAForm(props) {
	const [errors, setErrors] = useState({})

	const { setCurrentUser, setCurrentOrganizations } = useContext(UserContext)

	function handleSubmit(e) {
		e.preventDefault()

		var form_data = new FormData(e.target)

		var body = {
			USERNAME: props.login_info.USERNAME,
			SESSION: props.login_info.SESSION,
			CHALLENGE_NAME: props.login_info.method,
			CHALLENGE_RESPONSES: {
			}
		}
		form_data.forEach((value, key) => (body.CHALLENGE_RESPONSES[key] = value))

		authenticationService
			.twoFA(body)
			.then((user) => {
				setCurrentUser(user)
				mixpanelService.Enter2FA(user)
				return user
			})
			.then((user) => {
				organizationService.listByUser(user).then((organizations) => {
					let organ = {}
					organizations.map((org) => (organ[org.id] = org))
					setCurrentOrganizations(organ)
				})
			})
			.then(() => {
				props.onChange('logged', { user_id: props.login_info.USERNAME })
			})

			.catch((error) => {
				setErrors({ ...error })
			})
	}

	return (
		<form onSubmit={handleSubmit}>
			<InputField
				name={ props.login_info.method === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA_CODE' : 'SMS_MFA_CODE'}
				label='6-digit code'
				type='number'
				required={true}
				error={errors.non_field_errors}
			/>
			<Button text='Confirm' class='orange-btn my-30 full-width' />
		</form>
	)
}

export default TwoFAForm
