import React, {useState} from 'react'
import {Redirect} from 'react-router-dom'

// Components
import SendCodeCard from '../containers/VerifyEmail/SendCodeCard'
import VerificationCard from '../containers/VerifyEmail/VerificationCard'
import TermsOfServiceCard from '../containers/Signup/TermsOfServiceCard'
import OnboardingCard from '../containers/Signup/OnboardingCard'

function VerifyEmail(){
    const [stage, setStage] = useState("get_code")
    const [flow_props, setProps] = useState({})

    function changeStage(newstage, new_props){
        setProps(new_props)
        setStage(newstage)
    }

    var card
    if (stage === "get_code"){
        card = <SendCodeCard onChange={changeStage}/>
    }
    else if(stage === "verification"){
        card = <VerificationCard onChange={changeStage} signup_info={flow_props}/>
    }
    else if(stage === "terms_of_service"){
        card = <TermsOfServiceCard onChange={changeStage} signup_info={flow_props}/>
    }
    else if(stage === "onboarding"){
        card = <OnboardingCard onChange={changeStage} signup_info={flow_props}/>
    }
    else if (stage === "login") {
        return <Redirect to="./login" />
    } 

    return (
        <div className="row">
            {card}
        </div>
    )

}

export default VerifyEmail