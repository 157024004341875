import config from '../api/config'
import mixpanel from 'mixpanel-browser'

export const mixpanelService = {
	SignUp,
    EmailVerified,
    TermsOfServiceAccepted,
    LoginStep1,
    Enter2FA, 
    HomePageOpen,
    EnterStore,
    AddToShoppingCart,
    RemoveFromShoppingCart,
    //ShoppingCartCheckout1,
    //ShoppingCartCheckout2,
    //LeaveFeedbackEnter,
    //LeaveFeedbackSend,
    //ProfileOpened,  //need site in data
    //ProfileUpdated,
    //ExperienceAdded,
    //EducationAdded,
    //ExperienceEdited,
    //EducationEdited,
    Logout,
}

const DEBUG_MODE = true

async function SignUp(body) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
    var email = body["email"]
    mixpanel.identify(email)
    mixpanel.people.set({"$first_name":body["first_name"],
						"$last_name":body["last_name"],
                        "$phone_number":body["phone_number"],
                		"$dob":body["dob"],
                        "$email" : body["email"]

					})
	mixpanel.track(email, 'Sign up for Home')
	
}

async function EmailVerified(body) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
	mixpanel.track('User Email Verified')
}

async function TermsOfServiceAccepted() {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
	mixpanel.track('Terms of Service Accepted')
}

async function LoginStep1(body) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
    var email = body['USERNAME']
    mixpanel.identify(email) //Sets user

    mixpanel.people.set({
        "$email": email,
	})
	mixpanel.track('Home Login Step 1')
}

async function Enter2FA(body) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
	mixpanel.track('Enter2FA')
}

async function HomePageOpen(site) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
    mixpanel.track(
        "Open Site",
        {"Site": site}
        )
}

async function EnterStore() {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
	mixpanel.track('Enter Store')
}

async function AddToShoppingCart(product) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
    mixpanel.track(
        "Added to Cart",
        {
            "ProductId": product.id,
            "Product": product.name,
            "Price": product.price
        })
}

async function RemoveFromShoppingCart(product) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
    mixpanel.track(
        "Removed from Cart",
        {
            "ProductId": product.id,
            "Product": product.name,
            "Price": product.price
        })
}

async function ShoppingCartCheckout1(product) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
    mixpanel.track(
        "Enter Checkout",
        {
            "Product": product.name,
            "Price": product.price
        })
}

async function ShoppingCartCheckout2(product) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
    mixpanel.track(
        "Purchase Made",
        {
            "Product": product.name,
            "Price": product.price
        })
}

async function LeaveFeedbackEnter() {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
	mixpanel.track('Leave Feedback Enter')
}

async function LeaveFeedbackSend(body) {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
	mixpanel.track(
        "Leave Feedback Send",
        {"Action": body[""]}
        )
}

async function Logout() {
	mixpanel.init(config.mixpanelProjectId, {debug: DEBUG_MODE})
	mixpanel.track('Logout')
}