import React from "react";
import MultiStep from "../MultiSteps";
import VerifiedStepForm from "./VerifiedStepForm";

const VerifiedStep = ({
  onNext,
  currentMethod,
}) => {
  return (
    <>
      <h1 className="title-2 is-dark line-margin-0">
        {currentMethod === "SMS"
          ? "Two Factor Authentication"
          : "Authenticator App Setup"}
      </h1>
      <MultiStep current={4} />
      <VerifiedStepForm
        onNext={onNext}
        currentMethod={currentMethod}
      />
    </>
  );
};

export default VerifiedStep;
