import React, {useState} from 'react'

// Components
import RadioButton from '../../Elements/RadioButton'
import InputField from '../../Elements/InputField'
import Textarea from '../../Elements/Textarea'
import Button from '../../Elements/Button'

// API
import {profileService} from '../../../api'

function EducationForm(props) {
    const [errors, setErrors] = useState({})
    const [eduType, setEduType] = useState('education')
    const [submitting, setSubmitting] = useState(false)

    function handleSubmit(e){
        e.preventDefault()

        setSubmitting(true)

        var form_element = document.getElementById('signup-form')
        var form_data = new FormData(form_element)

        var body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = value
            }
        })
        profileService.create_info(body, "education")
        .then((response) => {
            props.onClose(true)
            setSubmitting(false)
        })
        .catch(error => {
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
            setSubmitting(false)
        })

    }

    let dates
    // eslint-disable-next-line eqeqeq
    if (eduType=="education") {
        dates = 
        <React.Fragment>
            <InputField name="start_date" label="Start date" type="date" class="flexer-6" required={true} error={errors.start_date}/>
            <InputField name="end_date" label="Finish date" type="date" class="flexer-6" error={errors.end_date}/>
        </React.Fragment>
    }
    // eslint-disable-next-line eqeqeq
    else if(eduType=="certificate") {
        dates = 
        <React.Fragment>
            <InputField name="start_date" label="Awarded Date" type="date" class="flexer-6" required={true} error={errors.start_date}/>
        </React.Fragment>
    }

    return (
        <form id="signup-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Add Education or Certificate</h1>
            <div className="row mt-15">
                <span className="col-2 heading is-dark side-margin-0">Type: </span>
                <RadioButton label="Education" class="col-3" name="type" value="Education" default={true} required={true} onClick={()=>{setEduType("education")}}/>
                <RadioButton label="Certificate" class="col-3" name="type" value="Certificate" required={true} onClick={()=>{setEduType("certificate")}}/>
            </div>
            <InputField name="school" label="School/Institution" type="text" required={true} error={errors.school}/>
            <InputField name="field_of_study" label="Field of Study" type="text" required={false} error={errors.field_of_study}/>
            <InputField name="level" label="Level" type="text" required={true} error={errors.level}/>
            <div className="flexer flexer-spaced mt-15">
                {dates}
            </div>
            <Textarea name="description" label="Description" error={errors.description || errors.non_field_errors}/>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" onClick={props.onClose}/>
                <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" disabled={submitting}/>
            </div>
        </form>
    )
}

export default EducationForm