import { useState } from "react"

import useOrgId from "../Hooks/useOrgId"
import { userService } from '../../api'
import Button from "./Button"
import Textarea from "./Textarea"
import RadioButton from "./RadioButton"

const FeedbackForm = (props) => {
	const { onClose } = props

	const [submitting, setSubmitting] = useState(false)
	const [bugFeedback, setBugFeedback] = useState(false)
	const [imagesUploaded, setImagesUploaded] = useState([])

	const orgId = useOrgId()

	function handleSubmit(e) {
		e.preventDefault()
		setSubmitting(true)

		const form_element = document.getElementById('feedback-form')
		const form_data = new FormData(form_element)

		userService
			.create_feedback(form_data)
			.then((_) => {
				onClose(true)
				setSubmitting(false)
			})
			.catch((error) => {
				if (Array.isArray(error)) {
					error = { non_field_errors: error[0] }
				}
				setSubmitting(false)
			})
	}

	const handleDelete = (e) => {
		let toDelete = e.currentTarget.getAttribute('name')
		let filtered = imagesUploaded.filter((files) => files.name !== toDelete)
		setImagesUploaded(filtered)
	}

	var docs = []
	if (imagesUploaded) {
		for (const doc of imagesUploaded) {
			docs.push(
				<div className='project-links flexer flexer-spaced flexer-vcenter'>
					<div className='flexer flexer-vcenter left'>
						<p className='far fa-file is-light line-margin-0' />
						<div className='mx-15'>
							<a
								href={doc.doc}
								className='text is-dark line-margin-0 cursor breakable'
								target='_blank'
								rel='noopener noreferrer'
							>
								{doc.name}
							</a>
							<p className='text is-light line-margin-0'>{parseInt(doc.size / 1000)} KB</p>
						</div>
					</div>
					<i className='fa fa-trash-o is-light cursor' name={doc.name} onClick={handleDelete} />
				</div>
			)
		}
	}

	return (
		<form id='feedback-form' onSubmit={handleSubmit}>
			<h1 className='title is-dark mb-30'>Send us feedback</h1>
			<div className='flexer flexer-vcenter mt-30'>
				<p className='subheading is-dark flexer-4'>Type of request: </p>
				<RadioButton
					label='Feature Request'
					class='flexer-5'
					name='bug'
					required={true}
					value='false'
					checked={bugFeedback}
					onClick={() => {
						setBugFeedback(!bugFeedback)
					}}
				/>
				<RadioButton
					label='Bug'
					class='flexer-4'
					name='bug'
					required={true}
					value='true'
					checked={!bugFeedback}
					onClick={() => {
						setBugFeedback(!bugFeedback)
					}}
				/>
			</div>
			<h1 className='text is-dark is-bold mt-30'>Add Comments</h1>
			<Textarea name='comments' label='' />
			<input name='project' type='hidden' value='Home' />
			<div className='right center-mobile'>
				{orgId && <input type='hidden' name='id_organization' value={orgId} />}
				<Button text='Cancel' class='mt-30 mx-15 white-btn subheading is-dark' onClick={onClose} />
				<Button
					text='Send'
					class='orange-btn mt-30 mx-15 subheading is-white'
					type='submit'
					disabled={submitting}
				/>
			</div>
		</form>
	)
}

export default FeedbackForm