import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import config from '../../../../api/config'
import FeedbackForm from '../../../Elements/FeedbackForm'
import Modal from '../../../Elements/Modal'

const HeaderUserMenu = ({ isOpen, id, hdlLogout }) => {
	const [isOpenFeeback, setIsOpenFeedback] = useState(false)

	return (
		<>
			<StyledNav isOpen={isOpen}>
				<StyledSpan>
					<Link to={`/profile/${id}`}>Profile</Link>
				</StyledSpan>

				<StyledSpan>
					<Link to={`/security/${id}`}>Security</Link>
				</StyledSpan>

				{/*<StyledSpan>
					<a href={`${config.home}/tickets/${id}`}>Tickets</a>
				</StyledSpan>*/}
        
				<StyledSpan onClick={()=>setIsOpenFeedback(true)}>Leave feedback</StyledSpan>
				<StyledSpan onClick={hdlLogout}>Logout</StyledSpan>
			</StyledNav>
			<Modal
				isOpen={isOpenFeeback}
				handleClose={() => {
					setIsOpenFeedback(false)
				}}
			>
				<FeedbackForm
					onClose={() => {
						setIsOpenFeedback(false)
					}}
				/>
			</Modal>
		</>
	)
}

const StyledNav = styled.nav`
	top: 3.5rem;
	right: 8rem;
	overflow: hidden;
	position: absolute;
	display: inline-grid;
	border-radius: 0.3rem;
	background-color: #fff;
	transition: height 0.5s ease-in-out;
	height: ${(props) => (props.isOpen ? 'fit-content' : '0')};
	border: ${(props) => (props.isOpen ? '1px solid #ececec' : 'none')};
`

const StyledSpan = styled.span`
	cursor: pointer;
	text-align: right;
	position: relative;
	padding: 0.5rem 1rem;
	transition: all 500ms ease-in;
	border-bottom: 1px solid #f9f9f9;

	:last-child {
		border-bottom: none;
	}

	:hover {
		background-color: #f4f6f9;
		color: #f69221;
	}

	:hover > * {
		color: #f69221;
		transition: all 500ms ease-in;
	}
`

export default HeaderUserMenu
