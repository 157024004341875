import React from 'react'

function Checkbox(props) {
    return (
        <div className="col-6">
            <label className="subheading is-light">
                <input className="checkbox" type="checkbox" name={props.name} required={props.required? true : false}/>{props.label} {props.children}
            </label>
            <p className="error-message">{props.error}</p>
        </div>
    )
}

export default Checkbox