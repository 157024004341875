import React from 'react'
import Title from '../../components/Elements/Title'
import Button from '../../components/Elements/Button'


const OnboardingCard = (props) => {
  const { onChange } = props
  function handleAccept() {
    onChange("login", {})
  }

  return (
    <div className="regist-card col-4 screen-center" >
      <div className="left">
        <Title text="Tutorial" />
      </div>

      <div className="my-30">
        <iframe className="full-width" width="560" height="315" src="https://www.youtube.com/embed/2h1hIYrOnCE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      <div className="center">
        <Button text="Next" class="orange-btn half-width" type="button" onClick={handleAccept} />
      </div>
    </div>
  )
}

export default OnboardingCard
