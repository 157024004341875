import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom'

// Hooks
import { UserContext } from '../components/Hooks/UserContext'


const PrivateRoute = ({ component: Component, ...rest }) => {

    const { currentUser, isLoading } = useContext(UserContext)
    return (
    <Route {...rest} render={props => {

        if(isLoading) {
            return <h1>Loading...</h1>
        }
        else if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: `/login/?redirect=${encodeURIComponent(window.location.href)}`, state: { from: props.location }}} />
        }
        // authorised so return component
        return <Component {...props} {...rest} />
    }} />)
}

export default PrivateRoute
