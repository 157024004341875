import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UserContext } from '../../components/Hooks/UserContext'

//API
import { profileService } from '../../api'

//Components
import SimpleUpper from '../../components/Profile/SimpleUpper'
import MenuTabItem from '../../components/Header/MenuTabItem'
import ExperienceSection from '../../components/Profile/ExperienceSection'
import ExperienceItem from '../../components/Profile/ExperienceItem'
import ReviewsSection from '../../components/Profile/ReviewsSection'
import ReviewsItem from '../../components/Profile/ReviewsItem'
import TermsOfServiceCard from '../Signup/TermsOfServiceCard'
import { HeaderWrapper } from '../../components/organisms/index.organisms'

function ProfileContent() {
    const { currentUser } = useContext(UserContext)

    let { user_id } = useParams()

    const logged_user_id = currentUser.cognito_id

    var allow_edit = false
    if (logged_user_id === user_id){
        allow_edit =  true
    }

    const [user, setUser] = useState({})
    const [section, setSection] = useState("experience")
    const [update_data, setUpdate] = useState(0)
    const [missingTerms, setMissingTerms] = useState(false)

    function upddateData(){
        setUpdate(update_data + 1)
    }

    useEffect(() => {
        profileService.retrieve(user_id)
        .then(user => {
            setUser(user)
            if(user.accept_terms_of_service === false && user_id === logged_user_id){
                setMissingTerms(true)
            }
        })
        .catch(error => {
            console.log("Error loading user data", error)
        })
    }, [update_data, user_id, logged_user_id])

    function handleAccept(newstage, new_props) {
        setMissingTerms(false)
    }

    if(missingTerms){
        return (
            <React.Fragment>
                <TermsOfServiceCard signup_info={currentUser} logged={true} onChange={handleAccept} />
            </React.Fragment>
        )
    } 

    if (user.profile) {
        var ExperienceItemEdu = []
        for (var education of user.profile.education) {
            var info1 = education.level
            info1 = education.field_of_study ? `${info1} (${education.field_of_study})` : info1

            var info2 = education.type === "Education" ? education.time_lapsed : education.proper_start_date
            ExperienceItemEdu.push(<ExperienceItem title={education.school} info1={info1} info2={info2} text={education.description} />)
        }

        var ExperienceItemExp = []
        for (var experience of user.profile.experience) {
            ExperienceItemExp.push(<ExperienceItem title={experience.title} emp_type={experience.proper_emp_type} info1={experience.company} info2={experience.time_lapsed} text={experience.description} />)
        }

        var ReviewItem = []
        for (var review of user.profile.reviews) {
            ReviewItem.push(<ReviewsItem text={review.review} rating={review.rating} name={review.reviewer.user.public_name} profile={review.reviewer.profile_pic}/>)
        }
    }
    
    var experienceSection
    if (section === "experience") {
        experienceSection = <React.Fragment>
                                <ExperienceSection title="Education & Certificates" button="Add Education or Certificate" btnClass="black-btn" btnIcon="fas fa-plus" type="education" onUpdate={upddateData} allow_edit={allow_edit}>
                                    {ExperienceItemEdu}
                                </ExperienceSection>
                                <ExperienceSection title="Previous Experience" button="Add Experience" btnClass="black-btn" btnIcon="fas fa-plus" type="experience" onUpdate={upddateData} allow_edit={allow_edit}>
                                    {ExperienceItemExp}
                                </ExperienceSection>
                            </React.Fragment>
    }
    else if(section === "reviews") {
        experienceSection = <React.Fragment>
                                <ReviewsSection title="Reviews" button="Add Review" btnClass="orange-btn" btnIcon="fa fa-star" onUpdate={upddateData} allow_edit={allow_edit} user={user}>
                                    {ReviewItem}
                                </ReviewsSection>
                            </React.Fragment>
    }
    
    return (
        <React.Fragment>
            {/* <HeaderWrapper /> */}
            <div className="flexer flexer-mobile">
                <div className="flexer-3 center">
                    <div className="simple-profile">
                        <SimpleUpper user={user} onUpdate={upddateData} allow_edit={allow_edit}/>
                    </div>
                </div>
                <div className="flexer-9 center">
                    <div className="advanced-profile flexer center-mobile">
                        <MenuTabItem text="Experience" active={section === "experience"} onClick={()=>{setSection("experience")}}/>
                        <MenuTabItem text="Reviews"  active={section === "reviews"} onClick={()=>{setSection("reviews")}}/>
                    </div>
                    {experienceSection}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProfileContent
