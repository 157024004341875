import {faTrash} from '@fortawesome/free-solid-svg-icons'
import {useContext, useEffect, useState} from 'react'
import NumberFormat from 'react-number-format'
import {toast} from 'react-toastify'
import styled from 'styled-components'

import {CartContext} from '../../../context/index.context'
import {BtnIconSingle, BtnSolid, FormInput} from '../../atoms/index.atoms'
import FormFieldSet from '../../molecules/Form/FieldSet'
import {mixpanelService} from '../../../api/mixpanel-service'
import {organizationService, subscriptionService} from '../../../api'
import useOrgId from '../../Hooks/useOrgId'

import CheckoutTermsOfServiceContent from '../../CheckoutTermsOfServiceContent'


const CartWrapper = ({
	cta,
	style,
	coupon,
	isOpen = true,
	isDisabledBtn,
	showPromoCode = true,
	title = 'My Shopping Cart',
	btnName = 'Go to checkout',
	btnType = 'button',
}) => {
	const [totalAmount, setTotalAmount] = useState(0)

	let promoCode = null
	let setPromoCode = null
	if (coupon?.promoCode !== undefined) {
		promoCode = coupon.promoCode
		setPromoCode = coupon.setPromoCode
	}

	const [promoError, setPromoError] = useState('')
	const [loadingPromo, setloadingPromo] = useState(false)
	const [discounts, setDiscounts] = useState([])

	let orgId = useOrgId()

	const {cartProducts, setCartProducts} = useContext(CartContext)

	const hdlRemoveProduct = (id, name) => {
		setCartProducts(cartProducts.filter((product) => product.id !== id))

		mixpanelService.RemoveFromShoppingCart(
				cartProducts.filter((product) => product.id === id))

		toast.success(
				`The product ${name} has been removed from your shopping cart.`)
	}

	const hdlTotalAmount = () => {
		setTotalAmount(
				cartProducts.reduce((total, {prices}) => total + prices[0]?.price, 0) +
				discounts.reduce((total, {prices}) => total + prices[0]?.price, 0))
	}

	useEffect(() => {
		hdlTotalAmount()
		setDiscounts([])
	}, [cartProducts])

	useEffect(() => {
		hdlTotalAmount()
	}, [discounts])

	useEffect(() => {
		setDiscounts([])
	}, [promoCode])

	const handlePromoCode = async () => {
		setloadingPromo(true)

		if (!orgId) {
			const organization = await organizationService.getOrCreate()
			orgId = organization.id
		}

		const body = {
			coupon: promoCode, prices: cartProducts.filter((prod) => prod?.id).
					map((prod) => prod.prices[0].id), organization: orgId,
		}
		subscriptionService.apply_code(body).then((response) => {
			setDiscounts(response.discounts.map((discount) => {
				return {name: promoCode, prices: [{price: discount}]}
			}))
		}).then(() => {
			setPromoError('')
			setloadingPromo(false)
		}).catch((error) => {
			console.error('Not able to apply coupon', error)
			setPromoError(error?.coupon)
			setloadingPromo(false)
		})
	}

	return (<CartWrapperStyled style={style} isOpen={isOpen}>
		{showPromoCode && <>
			<h1 style={{
				fontSize: '1rem', margin: 0, fontWeight: 'bold', color: '#F69221',
			}}>{title}</h1>
			{promoCode !== undefined && cartProducts.length > 0 && (<>
				<FormFieldSet name={'Promo Code'}
											style={{gridColumnStart: 1, gridColumnEnd: 4}}>
					<FormInput
							onChange={(e) => setPromoCode(e.target.value)}
							value={promoCode}
							name="coupon"
					/>
					{promoError ?? <p>{promoError}</p>}
					{promoCode && !discounts.length && (<BtnSolid
							style={{marginTop: '1rem'}}
							cta={handlePromoCode}
							name={'Apply Code'}
							type={'button'}
							isDisabled={promoCode === '' || loadingPromo}
					/>)}
				</FormFieldSet>
			</>)}
		</>}
		{cartProducts.length > 0 ? (<>
			<CartListItemsStyled>
				{cartProducts.map((product) => (<small
						key={`item-${product.id}`}
						style={{
							color: '#6B7280',
							display: 'inline-grid',
							width: '100%',
							gridAutoFlow: 'column',
							gridColumnGap: '.5rem',
							gridTemplateColumns: '1fr auto .8rem',
							alignItems: 'center',
						}}
				>
								<span style={{marginRight: '.5rem'}}>
									{product.name}{' '}
									{product?.prices[0]?.days_free_trial > 0 &&
											`(${product?.prices[0]?.days_free_trial} Free Trial Days)`}
								</span>
					{product?.prices[0]?.price === 0 || !product?.prices ? (
							<span style={{fontWeight: 'bold'}}>Free</span>) : (<NumberFormat
							prefix={'$'}
							decimalScale={2}
							displayType={'text'}
							thousandSeparator={true}
							fixedDecimalScale={true}
							style={{fontWeight: 'bold'}}
							value={product?.prices[0]?.price}
							thousandsGroupStyle={'thousand'}
					/>)}
					<BtnIconSingle
							icon={faTrash}
							cta={() => hdlRemoveProduct(product.id, product.name)}
					/>
				</small>))}
				{discounts.map((product) => (<small
						key={`item-${product.id}`}
						style={{
							color: '#6B7280',
							display: 'inline-grid',
							width: '100%',
							gridAutoFlow: 'column',
							gridColumnGap: '.5rem',
							gridTemplateColumns: '1fr auto .8rem',
							alignItems: 'center',
						}}
				>
					<span style={{marginRight: '.5rem'}}>{product.name}</span>
					{product?.prices[0]?.price === 0 || !product?.prices ? (<span
							style={{fontWeight: 'bold'}}>(Does not apply)</span>) : (
							<NumberFormat
									prefix={'$'}
									decimalScale={2}
									displayType={'text'}
									thousandSeparator={true}
									fixedDecimalScale={true}
									style={{fontWeight: 'bold'}}
									value={product?.prices[0]?.price}
									thousandsGroupStyle={'thousand'}
							/>)}
				</small>))}
			</CartListItemsStyled>
			<small
					style={{
						marginTop: '1rem',
						display: 'inline-flex',
						justifyContent: 'space-between',
					}}
			>
				<span>Total</span>
				<NumberFormat
						prefix={'$'}
						decimalScale={2}
						value={totalAmount}
						displayType={'text'}
						thousandSeparator={true}
						fixedDecimalScale={true}
						className={'ml-8 font-bold'}
						style={{fontWeight: 'bold'}}
						thousandsGroupStyle={'thousand'}
				/>
			</small>
		</>) : (
				<span style={{marginTop: '1rem'}}>Your cart is currently empty.</span>)}
		<BtnSolid
				cta={cta}
				name={btnName}
				type={btnType}
				style={{marginTop: '1rem'}}
				isDisabled={cartProducts.length === 0 || isDisabledBtn}
			/>
			{/* {cartProducts.length > 0 && btnName=='Pay Now' ? <CheckoutTermsOfServiceContent /> : '' } */}
		</CartWrapperStyled>
	)
}

const CartWrapperStyled = styled('article')`
  right: 0;
  top: 120%;
  display: flex;
  min-width: 15rem;
  width: max-content;
  position: absolute;
  overflow: hidden;
  flex-direction: column;
  background-color: white;
  border-style: solid;
  border-color: #ececec;
  padding: ${(props) => (props.isOpen ? '1rem' : '0')};
  border-width: ${(props) => (props.isOpen ? '1px' : '0')};
  height: ${(props) => (props.isOpen ? 'fit-content' : '0')};
`

const CartListItemsStyled = styled('ul')`
  margin: 0;
  display: grid;
  overflow-y: auto;
  padding: 0.5rem 0;
  max-height: 15rem;
  grid-row-gap: 1rem;
  border-bottom: 1px solid #ececec;
`

export default CartWrapper
