import React, {useState} from 'react'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'

// API
import {registrationService} from '../../api'

function ChangePasswordForm(props) {
    const [errors, setErrors] = useState({})

    function handleSubmit(e){
        e.preventDefault()

        var Pass1 = e.target.Password.value
        var Pass2 = e.target.PasswordConfirmation.value

        if (Pass1 === Pass2) {
            var form_element = document.getElementById('recover-form')
            var form_data = new FormData(form_element)

            var body = {};
            form_data.forEach((value, key) => body[key] = value);

            registrationService.confirm_forgot_password(body)
            .then((user) => {
                props.onChange("login", {})
            })
            .catch(error => {
                console.log("Error", error)
                setErrors({...error})
            })
        } else {
            setErrors({"PasswordConfirmation": "Passwords do not match."})
        }
    }

    return (
        <form id="recover-form" onSubmit={handleSubmit}>
            <InputField name="Username" type="hidden" value={props.recover_info.Username} />
            <InputField name="Password" id="password-1" label="New Password" type="password" required={true} error={errors.Password} />
            <InputField name="PasswordConfirmation" id="password-2" label="Confirm Password" type="password" required={true} error={errors.PasswordConfirmation} />
            <InputField name="ConfirmationCode" label="6-digit code" type="number" required={true} error={errors.ConfirmationCode || errors.non_field_errors} />
            <Button text="Change password" class="orange-btn my-30 full-width" type="submit" required={true} />
        </form>
    )
}

export default ChangePasswordForm