import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons'
import {FormFieldSet} from '../molecules/index.molecules'
import {FormInput} from '../atoms/index.atoms'
import Tooltip from '../Elements/Tooltip'
//import 'react-phone-number-input/style.css'
import moment from 'moment'

const RegisterForm = ( { register, errors, setError, watch, clearErrors } ) => {

	//const [value, setValue] = useState()
	const [ isPrivate, setIsPrivate ] = useState( true )
	const [ showPassword, setShowPassword ] = useState( false )
	const [ showConfirmPassword, setShowConfirmPassword ] = useState( false )

	const comparePasswords = () => {
		if ( watch( 'password' ) === watch( 'confirmPassword' ) ) {
			clearErrors()
		} else {
			setError( 'password', { message: 'Passwords do not match' } )
			setError( 'confirmPassword', { message: 'Passwords do not match' } )
		}
	}

	const hdlDate = () => {
		const age = moment( watch( 'dateBirth' ).replace( '-', '' ), 'YYYYMMDD' ).fromNow().split( ' ' )[ 0 ]

		if ( age === 'Invalid' ) {
			clearErrors()
		} else {
			if ( age >= 18 ) {
				clearErrors()
			} else {
				setError( 'dateBirth', { message: 'You must be 18 years old' } )
			}
		}
	}

	useEffect( () => {
		comparePasswords()
	}, [ watch( 'password' ), watch( 'confirmPassword' ) ] )

	useEffect( () => {
		hdlDate()
	}, [ watch( 'dateBirth' ) ] )

	const private_text =
		<div className="left" style={ { padding: '0px 10px' } }>
			<p className="text line-margin-0 is-ligth">A private profile displays only limited information. </p>
			<p className="text line-margin-0 is-ligth">Any member of your organization can see your public information, including your </p>
			<p className="text line-margin-0 is-ligth">name, profile picture, alias, email, social network links, and role.</p>
			<p className="text line-margin-0 is-ligth">Admins and super admins also can see your phone and occupation.</p>
			<p className="text line-margin-0 is-ligth">Anyone outside your organization only can see your name, profile picture, and alias.</p>
			<p className="text line-margin-0 is-ligth" />
		</div>

	return <>
		<FormTitle>User Registration</FormTitle>
		<FormWrapperStyled>
			<FormFieldSet isRequired={ true } onErr={ errors.firstName } name={ 'First Name' }>
				<FormInput onErr={ errors.firstName } customAttributes={ {
					...register(
						'firstName', { required: true } ),
				} }/>
			</FormFieldSet>
			<FormFieldSet name={ 'Middle Name' }>
				<FormInput customAttributes={ {
					...register(
						'middleName' ),
				} }/>
			</FormFieldSet>
			<FormFieldSet onErr={ errors.lastName } isRequired={ true } name={ 'Last Name' }>
				<FormInput onErr={ errors.lastName } customAttributes={ {
					...register(
						'lastName', { required: true } ),
				} }/>
			</FormFieldSet>
			<FormFieldSet name={ 'Alias' }>
				<FormInput customAttributes={ {
					...register(
						'alias' ),
				} }/>
			</FormFieldSet>
			<FormFieldSet onErr={ errors.dateBirth } isRequired={ true } name={ 'Date of Birth' } errMsg={ errors?.dateBirth?.message ?? null }>
				<FormInput type="date" onErr={errors.dateBirth} customAttributes={ {
					...register(
						'dateBirth', { required: { value: true, message: 'This field is required' } } ),
				} }/>
				<span className="text-signup is-dark left ">Note: You must be 18+ years of age.</span>
			</FormFieldSet>
			<FormFieldSet
				isRequired={ true }
				name={ 'Phone Number' }
				onErr={ errors?.phoneNumber }
				errMsg={ errors?.phoneNumber?.type === 'pattern'
					? 'Phone format: +15555555555 '
					: 'This field is required' } style={ { gridRowStart: 4, gridRowEnd: 5 } }
			>
				<FormInput
					onErr={ errors?.phoneNumber }
					customAttributes={ {
						...register( 'phoneNumber', {
							required: true, validate: ( value ) => {
								return !!value.trim()
							}, pattern: /[+][0-9]/,
						} ),
					} }
				/>
			</FormFieldSet>
			<FormFieldSet
				isRequired={ true }
				name={ 'Email' }
				onErr={ errors.email }
				style={ { gridRowStart: 4, gridRowEnd: 5, justifyContent: 'space-between' } }
			>
				<FormInput type="email" onErr={errors.email} customAttributes={ {
					...register(
						'email', { required: true } ),
				} }/>
			</FormFieldSet>
			<FormFieldSet style={ { gridRowStart: 5, gridRowEnd: 6 } } isRequired={ true } onErr={ errors.password } errMsg={ errors?.password?.message }
										name={ 'Password' }>
				<FormInput
					minLength={ 8 }
					type={ showPassword
						? 'text'
						: 'password' }
					onErr={errors.password}
					customAttributes={ {
						...register(
							'password', {
								required: { value: true, message: 'This field is required' },
								minLength: { value: 8, message: 'Passwords must be 8 characters minimum (upper case and lower case letters).' },
								pattern: {
									value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[{}()?"!@#%\$\^\*&/,><':;|_~`+=])(?=.{8,}).*$/i,
									message: 'Upper & lower case letters, symbol & number.',
								},
							} ),
					} }/>
				<FloatingIconStyled className={ 'cursor' }
														icon={ showPassword
															? faEyeSlash
															: faEye }
														onClick={ () => setShowPassword( !showPassword ) }/>
				<span className="text-signup is-dark left"> Note: Passwords must be 8 characters minimum (upper case and lower case letters).</span>
			</FormFieldSet>
			<FormFieldSet style={ { gridRowStart: 5, gridRowEnd: 6 } } isRequired={ true } onErr={ errors.confirmPassword }
										errMsg={ errors?.confirmPassword?.message ?? null } name={ 'Confirm Password' }>
				<FormInput
					minLength={ 8 }
					type={ showConfirmPassword
						? 'text'
						: 'password' }
					onErr={ errors.confirmPassword }
					customAttributes={ {
						...register(
							'confirmPassword', {
								required: { value: true, message: 'This field is required' },
								minLength: { value: 8, message: 'Passwords must be 8 characters minimum' },
								pattern: {
									value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[{}()?"!@#%\$\^\*&/,><':;|_~`+=])(?=.{8,}).*$/i,
									message: 'Upper & lower case letters, symbol & number.',
								},
							} ),
					} }/>
				<FloatingIconStyled className={ 'cursor' }
														icon={ showConfirmPassword
															? faEyeSlash
															: faEye }
														onClick={ () => setShowConfirmPassword(
															!showConfirmPassword ) }/>

			</FormFieldSet>
			<FormFieldSet style={ { gridRowStart: 6, gridRowEnd: 7 } } isRequired={ true } name={ 'Type of Profile' }>
				<ProfileTypeStyled>
					<Tooltip content={ private_text } direction="right-x">
						<label>
							<input type="radio" { ...register( 'profileType' ) } checked={ isPrivate }
										 value={ 'true' } onClick={ () => setIsPrivate( true ) }/>
							Private
						</label>
					</Tooltip>
					<label>
						<input type="radio" { ...register( 'profileType' ) } checked={ !isPrivate }
									 value={ 'false' } onClick={ () => setIsPrivate( false ) }/>
						Public
					</label>
					<span style={ { gridColumnStart: 1, gridColumnEnd: 3 } } className="text-signup is-dark left">Note: Public profiles indicate your profile is able to be discovered by other users, Private maintains your profile is hidden and unreadable by other users.</span>
				</ProfileTypeStyled>
			</FormFieldSet>
		</FormWrapperStyled>
	</>
}

const FormTitle = styled.h2`
  font-size: 1rem;
  margin: 0 0 20px;
`

const FormWrapperStyled = styled.aside`
  display: grid;
  gap: 1.5rem 1rem;
  grid-template-columns: repeat(2, calc(50% - .5rem));
`
const ProfileTypeStyled = styled.article`
  display: inline-grid;
  grid-template-columns: repeat(2, 50%);

  input {
    margin-right: .5rem;
  }
`

const FloatingIconStyled = styled( FontAwesomeIcon )`
  right: 0;
  bottom: 2.5rem;
  margin: auto;
  position: absolute;
`

export default RegisterForm
