const config = {
  apiUrl: process.env.REACT_APP_API_URI ?? 'https://api.torch.test.echoanalyticsgroup.com/api/v3',
  WebSocketURL: process.env.REACT_APP_WEBSOCKET_URL ?? 'wss://api.torch.test.echoanalyticsgroup.com/ws/chat/',
  mediaUri: process.env.REACT_APP_MEDIA_URI ?? 'https://633758392723-echo-files.s3.amazonaws.com/media',
  home: process.env.REACT_APP_HOME_URI ?? 'https://home.test.echoanalyticsgroup.com',
  cid: process.env.REACT_APP_CID_URI ?? 'https://cid.test.echoanalyticsgroup.com',
  torch: process.env.REACT_APP_TORCH_URI ?? 'https://torch.test.echoanalyticsgroup.com',
  externalApiKey: process.env.REACT_APP_ENV === 'test'
    ? 'Api-Key f5fQ3uiG.iX3a3V8WmgtOGj2XrX5swYdPp95Tppvf'
    : process.env.REACT_APP_ENV === 'demo'
      ? 'Api-Key eTRLmyI5.ANEWjHVYTDGADyt3xCYk2vrj6yo72Yon'
      : process.env.REACT_APP_ENV === 'prod'
        ? 'Api-Key lQXDI1q5.OG4WmefEFw9O7CBiusqbkOsqLS6OzeKg'
        : 'Api-Key f5fQ3uiG.iX3a3V8WmgtOGj2XrX5swYdPp95Tppvf',
  stripeClientSecret: process.env.REACT_APP_STRIPE_CLIENT_SECRET ?? 'pk_test_rVCbxqpCpuz3ctrAbssiCrMR',
  mixpanelProjectId: process.env.REACT_APP_ENV === 'test'
    ? 'c94354f1668cc1c489bb72e47f35d6ed'
    : process.env.REACT_APP_ENV === 'demo'
      ? '02e10ae1c6bd754704be985143648ee9'
      : process.env.REACT_APP_ENV === 'prod'
        ? '687b08df77d7d932ec170ffbb81bc9fa'
        : '',
}

export default config
