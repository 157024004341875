import React, {useState} from 'react'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'

// API
import {registrationService} from '../../api'

//Mixpanel
import { mixpanelService } from '../../api/mixpanel-service'

function VerificationForm(props) {
    const [errors, setErrors] = useState({})
    const [sendText, setSendText] = useState("Resend code")

    function handleSubmit(e){
        e.preventDefault()

        var form_data = new FormData(e.target)

        var body = {}
        form_data.forEach((value, key) => body[key] = value)
        
        registrationService.confirm_signup(body)
        .then((user) => {
            mixpanelService.EmailVerified(body)
            props.onChange("terms_of_service", {'user_id': props.signup_info.user_id})
            
        })
        .catch(error => {
            if (error.non_field_errors === "User cannot be confirmed. Current status is CONFIRMED"){
                props.onChange("terms_of_service", {'user_id': props.signup_info.user_id})
            }
            else{
                setErrors({...error})
            }
        })
    }

    function resendCode(){
        setSendText("Sending..." )
        var body = {'Username': props.signup_info.Username}
        registrationService.resend_code(body)
        .then((user) => {
            setSendText("Resend code")
        })
        .catch(error => {
            setSendText("Error sending code. Contact support")
            setErrors({...error})
        })
    }

    return(
        <form onSubmit={handleSubmit}>
            <InputField name="ConfirmationCode" label="6-digit code" type="number" required={true} error={errors.non_field_errors}/>
            <InputField name="Username" value={props.signup_info.Username} type="hidden" />
            <p className="right mt-15 link cursor" onClick={resendCode}>{sendText}</p>
            <Button text="Confirm" class="orange-btn my-30 full-width" />
        </form>
    )
}

export default VerificationForm