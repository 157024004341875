import PropTypes from 'prop-types'
import styled from 'styled-components'

const FormInput = ({
	min,
	type,
	name,
	step,
	value,
	onErr,
	onLoad,
	onChange,
	maxLength,
	isRequired,
	placeholder,
	customAttributes,
}) =>
	onLoad ? (
		// 		<LoaderFormInput />
		<></>
	) : (
		<StyledInput
			min={min}
			name={name}
			type={type}
			step={step}
			value={value}
			onErr={onErr}
			onChange={onChange}
			{...customAttributes}
			maxLength={maxLength}
			required={isRequired}
			placeholder={placeholder}
			 
		/>
	)

const StyledInput = styled.input`
  outline: none;
  border: none;
  border-bottom-width: 1px;
  padding: 0.25rem 0.125rem;
  border-bottom-style: solid;
  background-color: transparent;
  border-bottom-color: ${(props) => (props.onErr ? '#B91C1C80' : '#00000026')};
	transition: border 500ms ease-in-out;


	:focus {
		border-bottom-color: #f69221;
	}
`

FormInput.propTypes = {
	type: PropTypes.oneOf([
		'tel',
		'url',
		'date',
		'text',
		'color',
		'email',
		'month',
		'hidden',
		'number',
		'password',
	]).isRequired,
	min: PropTypes.string,
	name: PropTypes.string,
	step: PropTypes.string,
	onLoad: PropTypes.bool,
	value: PropTypes.string,
	onErr: PropTypes.bool,
	onChange: PropTypes.func,
	isRequired: PropTypes.bool,
	maxLength: PropTypes.number,
	placeholder: PropTypes.string,
	customAttributes: PropTypes.any,
}

FormInput.defaultProps = {
	type: 'text',
}
export default FormInput
