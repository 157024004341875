import React, {useState} from 'react'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'

// API
import {registrationService} from '../../api'

function SendCodeForm(props) {
    const [errors, setErrors] = useState({})

    function handleSubmit(e){
        e.preventDefault()

        var form_element = document.getElementById('recover-form')
        var form_data = new FormData(form_element)

        var body = {};
        form_data.forEach((value, key) => body[key] = value);

        registrationService.forgot_password(body)
        .then((user) => {
            props.onChange("change_password", {"Username": body["Username"]})
        })
        .catch(error => {
            setErrors({...error})
        })
    }

    return (
        <form id="recover-form" onSubmit={handleSubmit}>
            <InputField name="Username" label="Email" type="email" required={true} error={errors.non_field_errors}/>
            <Button text="Continue" class="orange-btn my-30 full-width" type="submit" />
        </form>
    )
}

export default SendCodeForm