import PropTypes from 'prop-types'

const Logo = ({className,name, img, style}) => <img src={img} alt={`Logo ${name}`} className={className} style={style}/>

Logo.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  img: PropTypes.string,
}

export default Logo
